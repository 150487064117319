import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EditForm } from "../../components";
import { useGetCategoryQuery, useUpdateCategoryMutation } from "../../store";

export default function EditCategory(props) {
  const { id } = useParams();

  const categoryAttr = [
    {
      type: "text",
      col: 12,
      props: {
        label: "Category Name",
        name: "name",
        maxLength: 50,
        minLength: 4,
        required: true,
      },
    },
  ];

  const [info, setInfo] = useState({ name: "", id });

  const [
    updateCategory,
    {
      isLoading: isUpdateCategoryLoading,
      isSuccess: isUpdateCategorySucess,
      error: updateCategoryError,
    },
  ] = useUpdateCategoryMutation();
  const { data } = useGetCategoryQuery(id);

  useEffect(() => {
    if (data?.category && data?.category.id === Number(id)) {
      setInfo({
        name: data.category.categoryName,
        id: Number(data.category.id),
      });
    }
  }, [id, data?.category]);

  const submitHandler = async (e) => {
    e.preventDefault();

    updateCategory(info);
  };

  return (
    <EditForm
      {...props}
      title="Edit Category"
      data={info}
      setData={setInfo}
      inputFieldProps={categoryAttr}
      submitHandler={submitHandler}
      target="/admin/category"
      successMessage="Category Updated Successfully! Redirecting..."
      reducerProps={{
        loadingUpdate: isUpdateCategoryLoading,
        error: updateCategoryError,
        success: isUpdateCategorySucess,
      }}
    ></EditForm>
  );
}
