import {  useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { MessageBox } from "../components";
import { jwtDecode } from "jwt-decode";

export default function AdminProtectedRoute({ children }) {
  // const { state, dispatch: ctxDispatch } = useContext(Store);
  // const { userInfo, token } = state;
  const user = JSON.parse(localStorage.getItem("user"));
  const token = JSON.parse(localStorage.getItem("token"));

  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = () => {
      if (token) {
        if (jwtDecode(token)?.exp < Date.now() / 1000) {
          // ctxDispatch({ type: "USER_SIGNOUT" });
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");

          navigate("/");
        }
      }
    };

    checkToken();
  }, [token, navigate]);

  return user ? (
    user.role === "Admin" ? (
      children
    ) : (
      <MessageBox variant={"danger"}>Restricted</MessageBox>
    )
  ) : (
    <Navigate to="/" />
  );
}
