export const getContent = () => {
    return {
        url: "/",
        method: "GET",
    };
};

export const deleteUser = (data) => {
    return {
        url: "/",
        method: "DELETE",
        body: { ...data }
    }
}

export const submitQuery = (data) => {
    return {
        url: "/",
        method: "POST",
        body: { ...data }
    }
}