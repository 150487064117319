//get all providers
export const getAllProviders = (query) => {
  return {
    url: "/",
    method: "GET",
    params: { ...query },
  };
};

//get provider by id
export const getProviderById = (providerId) => {
  return {
    url: `/${providerId}`,
    method: "GET",
  };
};

//update provider
export const updateProvider = (provider) => {
  return {
    url: `/${provider.id}`,
    method: "PUT",
    body: { ...provider },
  };
};

//delete provider
export const deleteProvider = (providerId) => {
  return {
    url: `/${providerId}`,
    method: "DELETE",
  };
};

//lock/unlock provider
export const lockUnlockProvider = (providerId) => {
  return {
    url: `/state/${providerId}`,
    method: "PUT",
  };
};