import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { motion } from "framer-motion";
import { useGetBannerByIdQuery } from "../../store";
import { useEffect, useState } from "react";
import { MessageBox } from "../../components";
import EditBanner from "./Edit";

const View = () => {
  const { id } = useParams();
  const [modalShow, setModalShow] = useState(false);
  const { data, isLoading, error, isError, refetch } = useGetBannerByIdQuery(id);

  const getDateTime = (dt) => {
    const dT = dt.split(".")[0].split("T");
    return `${dT[0]} ${dT[1]}`;
  };

  useEffect(()=>{
    refetch(id)
  },[id, refetch])

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.75, ease: "easeInOut" }}
      exit={{ x: "100%" }}
    >
      <Container fluid className="py-3">
        {isError ? (
          <MessageBox variant="danger">{error.data.error.message}</MessageBox>
        ) : (
          <>
            <Card>
              <Card.Header>
                <Card.Title>Banner Details</Card.Title>

                <div className="card-tools">
                  <FaEdit
                    style={{ color: "blue" }}
                    onClick={() => setModalShow(true)}
                  />
                </div>
              </Card.Header>
              <Card.Body>
                <Row className="mb-3">
                  <Col>
                    {isLoading ? (
                      <Skeleton height={200} />
                    ) : (
                      <img
                        src={data?.banner?.url}
                        alt=""
                        className="img-fluid"
                        width={"60%"}
                      />
                    )}
                  </Col>
                </Row>

                <Row className="mb-3">
                <Col md={6}>
                    <p className="mb-0">
                      <strong>Navigation Link</strong>
                    </p>
                    <a href={data?.banner?.link}>
                      {isLoading ? (
                        <Skeleton />
                      ) : (
                        data?.banner?.link? data?.banner?.link : "Not Set"
                      )}
                    </a>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <p className="mb-0">
                      <strong>Created At</strong>
                    </p>
                    <p>
                      {isLoading ? (
                        <Skeleton />
                      ) : (
                        getDateTime(data?.banner?.createdAt)
                      )}
                    </p>
                  </Col>
                  <Col md={6}>
                    <p className="mb-0">
                      <strong>Last Update</strong>
                    </p>
                    <p>
                      {isLoading ? (
                        <Skeleton />
                      ) : (
                        getDateTime(data?.banner?.updatedAt)
                      )}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <EditBanner
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
            <ToastContainer />
          </>
        )}
      </Container>
    </motion.div>
  );
};

export default View;
