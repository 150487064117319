//get content
export const getContent = () => {
  return {
    url: "/",
    method: "GET",
  };
};

//delete content
export const deleteContent = (type) => {
  return {
    url: `/?type=${type}`,
    method: "DELETE",
  };
};

//create content
export const createContent = (content) => {
  return {
    url: "/",
    method: "POST",
    body: {
      title: content.title,
      desc: content.desc,
    },
  };
};


//update content
export const updateContent = (content) => {
  return {
    url: "/",
    method: "POST",
    body: {
      title: content.title,
      desc: content.desc,
    },
  };
};
