import "./SideNavBar.css";
import { useState } from "react";
import { Link } from "react-router-dom";

import { RiDashboard2Fill } from "react-icons/ri";
import { FaSignOutAlt } from "react-icons/fa";
import { GrServices } from "react-icons/gr";
import { MdOutlineCategory } from "react-icons/md";
import { FaUsers } from "react-icons/fa6";
import { logoutUser } from "../../store";
import { GiKnightBanner } from "react-icons/gi";
import { BiBookContent } from "react-icons/bi";
import { MdOutlineLocalLaundryService } from "react-icons/md";
import { MdContactPhone } from "react-icons/md";

const linkList = [
  {
    icon: <RiDashboard2Fill className="icon-md" />,
    text: "Dashboard",
    url: "/admin/dashboard",
  },
  {
    icon: <MdOutlineCategory className="icon-md" />,
    text: "Category",
    url: "/admin/category",
  },
  {
    icon: <GrServices className="icon-md" />,
    text: "Services",
    url: "/admin/services",
  },
  {
    icon: <FaUsers className="icon-md" />,
    text: "Users",
    url: "/admin/users",
  },
  {
    icon: <MdOutlineLocalLaundryService className="icon-md" />,
    text: "Providers",
    url: "/admin/providers",
  },
  {
    icon: <BiBookContent className="icon-md" />,
    text: "Content",
    url: "/admin/content",
  },
  {
    icon: <GiKnightBanner className="icon-md" />,
    text: "Banners",
    url: "/admin/banners",
  },
  {
    icon: <MdContactPhone className="icon-md" />,
    text: "Enquiries",
    url: "/admin/enquiries",
  },
];

const active_text = {
  Dashboard: "dashboard",
  Users: "users",
  Category: "category",
  Services: "services",
  Providers: "providers",
  Content: "content",
  Banners: "banners",
  Enquiries: "enquiries",
};

export default function SideNavbar({ isExpanded }) {
  const pathname = window.location.pathname;
  const [, setActiveLink] = useState("Dashboard");
  const userInfo = JSON.parse(localStorage.getItem("user"));

  const signoutHandler = () => {
    logoutUser();
  };

  const activeLinkHandler = (text) => {
    return pathname.includes(active_text[text]);
  };

  const cls = `nav-item has-treeview ${isExpanded ? "menu-item" : "menu-item menu-item-NX"
    }`;

  return (
    <>
      {userInfo ? (
        <div
          className={
            isExpanded
              ? "side-nav-container"
              : "side-nav-container side-nav-container-NX"
          }
        >
          <div className="brand-link">
            <a href="/">
              <img
                src="/Logo.png"
                alt=""
                width={isExpanded ? "20%" : "85%"}
                height="auto"
              />
            </a>
            <span className="brand-text ms-2 font-weight-light">My Pitch</span>
          </div>

          <div className="sidebar">
            {/* Sidebar user panel (optional) */}
            <div className="user-panel mt-3 pb-3 mb-3 d-flex">
              <div className="info">
                {/* <Link to={`${userInfo.role}/view-profile`} className="d-block"> */}
                {/* {userInfo.profile_img && (
                    <img
                      src={userInfo.profile_img}
                      alt=""
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        marginRight: "0.5rem",
                      }}
                    />
                  )} */}
                <span className="info-text">
                  Welcome {userInfo ? userInfo.fullname : "Back"}
                </span>
                {/* </Link> */}
              </div>
            </div>
            {/* Sidebar Menu */}
            <nav className="mt-2">
              <ul
                className="nav-pills nav-sidebar px-0 d-flex flex-column flex-wrap"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
              >
                {linkList.map(({ icon, text, url }) => (
                  <li
                    key={url}
                    className={`${cls} ${activeLinkHandler(text) && "active-item"
                      }`}
                    onClick={() => setActiveLink(text)}
                  >
                    <Link to={url} className="nav-link">
                      {icon}
                      <p className="ms-2">{text}</p>
                    </Link>
                  </li>
                ))}

                <li className={cls}>
                  <Link onClick={signoutHandler} to="/" className="nav-link">
                    <FaSignOutAlt className="icon-md" />
                    <p className="ms-2">Log Out</p>
                  </Link>
                </li>
              </ul>
            </nav>
            {/* /.sidebar-menu */}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
