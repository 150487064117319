import React from 'react';
import { Card, Spinner, Alert } from 'react-bootstrap';
import { useGetPrivacyQuery } from '../../store';

const PrivacyPolicy = () => {
    const {
        data: content,
        isLoading: isContentLoading,
        isError: isContentError,
        error: contentError,
        isSuccess: isContentSuccess,
        refetch,
    } = useGetPrivacyQuery();

    return (
        <div className='container py-5'>
            {/* <Card border='0px'>
                <Card.Body border='0px'> */}
            {isContentLoading && <Spinner animation="border" />}
            {isContentError && (
                <Alert variant="danger">
                    {contentError?.data?.message || "An error occurred while fetching the privacy policy."}
                </Alert>
            )}
            {isContentSuccess && content && (
                <div
                    style={{ overflowY: "auto" }}
                    dangerouslySetInnerHTML={{ __html: content.data }}
                />
            )}
            {!isContentLoading && !isContentError && !content && (
                <Alert variant="warning">No content available.</Alert>
            )}
            {/* </Card.Body>
            </Card> */}
        </div>
    );
}

export default PrivacyPolicy;
