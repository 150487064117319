import React, { useEffect, useReducer, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
// icons
import { MdContactPhone } from "react-icons/md";
import { BsShieldFillPlus } from "react-icons/bs";
import { FaArrowCircleRight } from "react-icons/fa";
import { BiSolidShieldMinus, BiSolidShieldX } from "react-icons/bi";
import { HiShieldCheck, HiShieldExclamation } from "react-icons/hi";

import Skeleton from "react-loading-skeleton";
import axiosInstance from "../utils/axiosUtil";
import { getError } from "../utils/error";
import { MotionDiv, MessageBox, CountUp } from "../components";
import { useGetSummaryQuery } from "../store";

const card = [
  {
    key: "awaited",
    bg: "primary",
    icon: <BsShieldFillPlus />,
    text: "Awaited Warranties",
    url: "/admin/warranty/?status=AWAITED",
  },
  {
    key: "passed",
    bg: "success-1",
    icon: <HiShieldCheck />,
    text: "Passed Warranties",
    url: "/admin/warranty/?status=PASSED",
  },
  {
    key: "active",
    bg: "success",
    icon: <HiShieldCheck />,
    text: "Active Warranties",
    url: "/admin/warranty/?status=ACTIVE",
  },
  {
    key: "rejected",
    bg: "danger",
    icon: <BiSolidShieldX />,
    text: "Rejected Warranties",
    url: "/admin/warranty/?status=REJECTED",
  },
  {
    key: "toExpired",
    bg: "warning",
    icon: <HiShieldExclamation />,
    text: "Expiring Warranties In 30 Days",
    url: "/admin/warranty/?status=TO-BE-EXPIRED",
  },
  {
    key: "expired",
    bg: "danger",
    icon: <BiSolidShieldMinus />,
    text: "Expired Warranties",
    url: "/admin/warranty/?status=EXPIRED",
  },
  {
    key: "enquiry",
    bg: "info",
    icon: <MdContactPhone />,
    text: "Enquiry",
    url: "/admin/enquiry",
  },
];

export default function Dashboard() {
  const { data, isLoading, refetch } = useGetSummaryQuery();
  console.log(".....................", data);

  const navigate = useNavigate();

  useEffect(() => {
    refetch();
  }, [refetch]);

  const Count = ({ title, num, target }) => {
    return isLoading ? (
      <Skeleton count={1} height={100} width={400} baseColor="#afafaf" />
    ) : (
      <div
        className=" shadow col-3 rounded-3 hover"
        onClick={() => (target ? navigate(`/admin/${target}`) : "")}
      >
        <p className="d-flex flex-column p-3 rounded-3 align-items-center gap-1 border mb-0 m-0 text-secondary">
          <strong className="fs-5">{title}</strong>
          <span className="purple fs-4 text-bold">{num}</span>
        </p>
        <p
          className="mb-0 text-bold text-center"
          style={{ backgroundColor: "#afafaf1a" }}
        >
          Know More
        </p>
      </div>
    );
  };

  return (
    <MotionDiv>
      <h3 className="mb-4 mx-1">Dashboard</h3>
      <div className="d-flex gap-2 flex-wrap flex-grow-1 rounded-3 m-1">
        <Count title={"Users"} target={"users"} num={data?.users} />
        <Count title={"Providers"} target={"providers"} num={data?.providers} />
        <Count title={"Services"} target={"services"} num={data?.services} />
        <Count
          title={"Categories"}
          target={"category"}
          num={data?.categories}
        />
        <Count title={"Wishlist"} target={""} num={data?.wishlists} />
        <Count title={"Enquiries"} target={"enquiries"} num={data?.enquiries} />
      </div>
    </MotionDiv>
  );
}
