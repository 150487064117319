import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Row,
    Spinner,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { FaEnvelope, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import { useTitle } from "../../components";
import { toastOptions } from "../../utils/error";
import { useDispatch } from "react-redux";
import { useDeleteUser } from "../../store";

const DeleteAccount = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPwd, setShowPwd] = useState(false);
    const [
        deleteAccount,
        {
            isLoading: isDeleteLoading,
            error: deleteError,
            isError: isDeleteError,
            isSuccess: isDeleteSuccess,
        },
    ] = useDeleteUser();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const submitHandler = async (e) => {
        e.preventDefault();
        if (!email || !password) return;

        deleteAccount({ email, password });
    };

    useEffect(() => {
        if (isDeleteError) {
            toast.error(deleteError?.data.error.message, toastOptions);
        }
    }, [deleteError?.data, isDeleteError]);

    useEffect(() => {
        if (isDeleteSuccess) {
            toast.success("Account deleted successfully", toastOptions);
            setEmail("")
            setPassword("")
        }
    }, [isDeleteSuccess, navigate]);

    useTitle("Delete Account");
    return (
        <Container fluid className="p-0 vh-100 f-center flex-column login-page">
            <div className="login-logo">
                <Link to="/" className="text-center">
                    <b>BIZ PITCH</b>
                </Link>
            </div>

            <Card className="login-box">
                <Card.Body>
                    <p className="text-center">Delete account</p>
                    <Form onSubmit={submitHandler}>
                        {/* Email */}
                        <Form.Group controlId="text" className="input-group mb-3">
                            <Form.Control
                                placeholder="Email"
                                value={email}
                                type="text"
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <InputGroup.Text>
                                <FaEnvelope />
                            </InputGroup.Text>
                        </Form.Group>
                        {/* Password */}
                        <Form.Group controlId="password" className="input-group mb-3">
                            <Form.Control
                                placeholder="Password"
                                type={showPwd ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <InputGroup.Text
                                className="toggle-pwd"
                                onClick={() => setShowPwd(!showPwd)}
                            >
                                {showPwd ? <FaEyeSlash /> : <FaEye />}
                            </InputGroup.Text>
                            <InputGroup.Text>
                                <FaLock />
                            </InputGroup.Text>
                        </Form.Group>
                        <Row>
                            <Col>
                                {isDeleteLoading ? (
                                    <Button type="submit" className="float-sm-end" disabled>
                                        <Spinner animation="border" size="sm" />
                                    </Button>
                                ) : (
                                    <Button
                                        id="delete_submit"
                                        type="submit"
                                        className="float-sm-end btn-danger"
                                    >
                                        Delete
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </Form>
                    <ToastContainer />
                </Card.Body>
            </Card>
        </Container>
    );
}
export default DeleteAccount
