import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { categoryBaseUrl } from "../routes";
import { headerWithAuthToken } from "../headers";
import {
  createCategory,
  deleteCategory,
  getAllCategories,
  getCategory,
  updateCategory,
} from "./queries";

const categoryApi = createApi({
  reducerPath: "category",
  baseQuery: fetchBaseQuery({
    baseUrl: categoryBaseUrl,
    prepareHeaders: headerWithAuthToken,
  }),
  endpoints: (builder) => ({
    // get all categories
    getAllCategories: builder.query({
      providesTags: ["Category"],
      query: getAllCategories,
    }),
    //get category by category id
    getCategory: builder.query({
      providesTags: ["Category"],
      query: getCategory,
    }),
    //create new category
    createCategory: builder.mutation({
      invalidatesTags: ["Category"],
      query: createCategory,
    }),
    //update existing category
    updateCategory: builder.mutation({
      // invalidatesTags: ["Category"],
      query: updateCategory,
    }),
    // delete category
    deleteCategory: builder.mutation({
      invalidatesTags: ["Category"],
      query: deleteCategory,
    }),
  }),
});

export const {
  useGetAllCategoriesQuery,
  useGetCategoryQuery,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useCreateCategoryMutation,
  reducer: categoryReducer,
  reducerPath: categoryReducerPath,
  middleware: categoryMiddleware,
} = categoryApi;
