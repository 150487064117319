import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { AddForm, EditForm, useTitle } from "../../components";
import { ToastContainer } from "react-toastify";
import JoditEditor from "jodit-react";
import Skeleton from "react-loading-skeleton";
import { useGetContentQuery, useUpdateContentMutation } from "../../store";

const TCU = "TERMS_AND_CONDITIONS_USER";
const TCP = "TERMS_AND_CONDITIONS_PROVIDER";
const PPU = "PRIVACY_POLICY_USER";
const PPP = "PRIVACY_POLICY_PROVIDER";

export default function EditContent() {
  const [searchParams, _] = useSearchParams(document.location.search);
  const contentType = searchParams.get("TYPE");
  const title = contentType === "TC" ? "Terms & Conditions" : "Privacy Policy";
  const {
    data: content,
    isLoading: isContentLoading,
    isError: isContentError,
    error: contentError,
    isSuccess: isContentSuccess,
  } = useGetContentQuery();

  const editor = useRef();
  const [value, setValue] = useState();
  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: "Write your content here...",
    }),
    []
  );

  const [
    updateContent,
    {
      isLoading: isCreateContentLoading,
      isError: isCreateContentError,
      error: createContentError,
      isSuccess: isCreateContentSuccess,
    },
  ] = useUpdateContentMutation();

  useEffect(() => {
    if (editor.current) {
      const domScroll = document.querySelector(".jodit-workplace>div");
      domScroll.scrollTop = domScroll.scrollHeight;
    }
  }, [value]);

  const resetForm = () => setValue("");

  const submitHandler = async (e) => {
    e.preventDefault();

    let title;

    if (contentType === "TCU") {
      title = TCU;
    } else if (contentType === "TCP") {
      title = TCP;
    } else if (contentType === "PPU") {
      title = PPU;
    } else {
      title = PPP;
    }

    updateContent({ title, desc: value });
    resetForm();
  };

  useEffect(() => {
    if (contentType === "TCU") {
      setValue(content?.ttU?.desc);
    } else if (contentType === "TCP") {
      setValue(content?.ttP?.desc);
    } else if (contentType === "PPU") {
      setValue(content?.ppU?.desc);
    } else {
      setValue(content?.ppP?.desc);
    }
  }, [
    content?.ttU?.desc,
    content?.ttP?.desc,
    content?.ppU?.desc,
    content?.ppP?.desc,
    contentType,
  ]);

  useTitle(`Content | ${title}`);

  return (
    <AddForm
      title={`Edit ${title}`}
      data={{}}
      setData={() => {}}
      inputFieldProps={[]}
      submitHandler={submitHandler}
      target="/admin/content"
      successMessage={`${title} Updated Successfully! Redirecting...`}
      reducerProps={{
        isLoading: isCreateContentLoading,
        error: createContentError,
        isSuccess: isCreateContentSuccess,
      }}
    >
      {isContentLoading ? (
        <Skeleton count={5} height={40} />
      ) : (
        <JoditEditor
          ref={editor}
          value={value}
          config={config}
          tabIndex={1} // tabIndex of textarea
          // onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
          onChange={(newContent) => setValue(newContent)}
        />
      )}
      {/* {content} */}
      <ToastContainer />
    </AddForm>
  );
}
