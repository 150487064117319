import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { adminBaseUrl } from "../routes";
import { headerWithAuthToken } from "../headers";
import { getProfile, getSummary, updateProfile } from "./queries";

const adminApi = createApi({
  reducerPath: "admin",
  baseQuery: fetchBaseQuery({
    baseUrl: adminBaseUrl,
    prepareHeaders: headerWithAuthToken,
  }),
  endpoints: (builder) => ({
    //get admin summary
    getSummary: builder.query({
      query: getSummary,
    }),

    //get admin profile
    getProfile: builder.query({
      query: getProfile,
    }),

    //update admin profile
    updateProfile: builder.mutation({
      query: updateProfile,
    }),
  }),
});

export const {
  useGetSummaryQuery,
  useGetProfileQuery,
  useUpdateProfileMutation,
  reducer: adminReducer,
  reducerPath: adminReducerPath,
  middleware: adminMiddleware,
  endpoints: adminEndpoints,
} = adminApi;
