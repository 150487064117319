export const adminBaseUrl = "https://api.bizpitch.ca/api/admin"; //hosted
// export const adminBaseUrl = "http://localhost:5000/api/admin"; //local

export const publicBaseUrl = "https://api.bizpitch.ca/api"; //hosted
// export const publicBaseUrl = "http://localhost:5000/api"; //local

export const serviceBaseUrl = adminBaseUrl + "/service";
export const categoryBaseUrl = adminBaseUrl + "/category";

export const userBaseUrl = adminBaseUrl + "/user";
export const providerBaseUrl = adminBaseUrl + "/provider";

export const contentBaseUrl = adminBaseUrl + "/content";

export const bannerBaseUrl = adminBaseUrl + "/banner";

export const enquiryBaseUrl = adminBaseUrl + "/enquiries"

export const termsAndConditions = publicBaseUrl + "/content/terms_and_conditions";
export const privacyPolicy = publicBaseUrl + "/content/privacy_policy";
export const deleteUserUrl = publicBaseUrl + "/users/delete-account";
export const submitQueryUrl = publicBaseUrl + "/query/create-query";