import { ToastContainer, toast } from "react-toastify";
import {
  CustomTable,
  DeleteButton,
  MessageBox,
  MotionDiv,
  ViewButton,
  useTitle,
} from "../../components";
import { useEffect, useState } from "react";
import { useDeleteServiceMutation, useGetAllServicesQuery } from "../../store";
import { useNavigate } from "react-router-dom";
import { toastOptions } from "../../utils/error";
// import { useSelector } from "react-redux";

export default function Services() {
  useTitle("Services");
  const [, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();

  const {
    data: services,
    isLoading: isServicesLoading,
    isError: isServicesError,
    error: servicesError,
    isFetching: isServicesFetching,
    refetch,
  } = useGetAllServicesQuery({
    keyword: searchInput,
    currentPage,
    resultPerPage,
  });

  useEffect(() => {
    refetch({
      keyword: searchInput,
      currentPage,
      resultPerPage,
    });
  }, [currentPage, refetch, resultPerPage, searchInput]);

  const [
    removeService,
    {
      isSuccess: isServiceDeleted,
      isLoading: isServiceLoading,
      isError: isServiceError,
      error: serviceError,
    },
  ] = useDeleteServiceMutation();

  useEffect(() => {
    refetch({ keyword: searchInput, currentPage, resultPerPage });
  }, [currentPage, refetch, resultPerPage, searchInput]);

  useEffect(() => {
    if (isServiceDeleted) {
      refetch({
        keyword: searchInput,
        currentPage,
        resultPerPage,
      });
    }
  }, [isServiceDeleted, currentPage, refetch, resultPerPage, searchInput]);

  useEffect(() => {
    if (isServiceError) {
      toast.error(serviceError?.data?.error.message, toastOptions);
    }

    if (isServiceDeleted) {
      toast.success("Service Deleted Successfully.", toastOptions);
    }
  }, [isServiceDeleted, isServiceError, serviceError]);

  const curPageHandler = (p) => setCurrentPage(p);

  const column = ["S.No", "Image", "Service Title", "Actions"];
  const numOfPages = Math.ceil(services?.count / resultPerPage);
  const skip = resultPerPage * (currentPage - 1);
  // const admin = useSelector((state) => state.adminSlice);
  // console.log("admin", admin);
  return (
    <MotionDiv>
      {isServicesError ? (
        <MessageBox variant="danger">
          {servicesError.data.error.message}
        </MessageBox>
      ) : (
        <CustomTable
          loading={isServicesLoading || isServicesFetching}
          column={column}
          rowNo={resultPerPage}
          rowProps={{ setResultPerPage }}
          paging={numOfPages > 0}
          pageProps={{ numOfPages, currentPage }}
          pageHandler={curPageHandler}
          search={true}
          searchProps={{ searchInput, setSearchInput, setQuery }}
          isCreateBtn="true"
          createBtnProps={{
            createURL: "/admin/services/create",
            text: "Service",
          }}
          // isTitle="true"
          // title="Service"
        >
          {services?.services &&
            services.services.map((service, i) => {
              return (
                <tr key={i} className="odd">
                  <td className="text-center">{skip + i + 1}</td>
                  <td>
                    <img className="td-img" src={service.image} alt="img" />
                  </td>
                  <td>{service.title}</td>
                  <td>
                    <ViewButton
                      onClick={() =>
                        navigate(`/admin/view/services/${service.id}`)
                      }
                    />
                    <DeleteButton onClick={() => removeService(service.id)} />
                  </td>
                </tr>
              );
            })}
        </CustomTable>
      )}
      <ToastContainer />
    </MotionDiv>
  );
}
