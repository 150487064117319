import { ToastContainer, toast } from "react-toastify";
import {
  CustomTable,
  DeleteButton,
  MessageBox,
  MotionDiv,
  ViewButton,
  useTitle,
} from "../../components";
import { useEffect, useState } from "react";
import {
  useGetAllBannersQuery, useRemoveBannerMutation,
} from "../../store";
import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { toastOptions } from "../../utils/error";

export default function User() {
  useTitle("Banners");
  const [, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();

  const {
    data: banners,
    isLoading: isBannersLoading,
    isError: isBannersError,
    error: bannersError,
    refetch
  } = useGetAllBannersQuery();
  console.log(banners);

  const [
    removeBanner,
    {
      isSuccess: isBannerDeleted,
      isError: isBannerDeleteError,
      error: bannerDeleteError,
    },
  ] = useRemoveBannerMutation();


  const curPageHandler = (p) => setCurrentPage(p);

  const column = [
    "S.No",
    "Client Name",
    "Banner Image",
    "Banner Link",
    "Actions",
  ];
  const numOfPages = Math.ceil(banners?.count / resultPerPage);
  const skip = resultPerPage * (currentPage - 1);

  useEffect(() => {
    if (isBannerDeleteError) {
      toast.error(bannerDeleteError.data.error.message, toastOptions);
    }

    if (isBannerDeleted) {
      toast.success("Banner Deleted Successfully.", toastOptions);
    }
  }, [isBannerDeleteError, isBannerDeleted, bannerDeleteError]);

  useEffect(() => {
    refetch({ keyword: searchInput, currentPage, resultPerPage });
  }, [currentPage, refetch, resultPerPage, searchInput]);

  useEffect(() => {
    if (isBannerDeleted) {
      refetch();
    }
  }, [isBannerDeleted, refetch]);

  return (
    <MotionDiv>
      {isBannersError ? (
        <MessageBox variant="danger">
          {bannersError.data.error.message}
        </MessageBox>
      ) : (
        <CustomTable
          loading={isBannersLoading}
          column={column}
          rowNo={resultPerPage}
          rowProps={{ setResultPerPage }}
          paging={numOfPages > 0}
          pageProps={{ numOfPages, currentPage }}
          pageHandler={curPageHandler}
          // search={true}
          // searchProps={{ searchInput, setSearchInput, setQuery }}
          isCreateBtn="true"
          createBtnProps={{
            createURL: "/admin/banners/create",
            text: "Banner",
          }}
          // isTitle="true"
          // title="Users"
        >
          {banners?.banners &&
            banners.banners.map((banner, i) => {
              return (
                <tr key={i} className="odd">
                  <td className="text-center">{skip + i + 1}</td>
                  <td>
                    <p>{banner.client_name}</p>
                  </td>
                  <td>
                    <img className="td-img" src={banner.url} alt="img" />
                  </td>
                  <td>
                    {banner.link? banner.link: "Link is not set"}
                  </td>
                  <td>
                    <ViewButton
                      onClick={() => navigate(`/admin/view/banners/${banner.id}`)}
                    />
                    <DeleteButton onClick={() => removeBanner(banner.id)} />
                  </td>
                </tr>
              );
            })}
        </CustomTable>
      )}
      <ToastContainer />
    </MotionDiv>
  );
}
