import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import { useTitle, ViewCard } from "../../components";
import { useGetServiceQuery } from "../../store";
import EditService from "./Edit";

const keyProps = {
  "Service ID": "id",
  "Service Title": "title",
  "Category": "category",
  "Created At": "createdAt",
  "Last Update": "updatedAt",
};

const View = () => {
  const { id } = useParams();

  const [modalShow, setModalShow] = useState(false);
  const { data, isLoading, error, isSuccess, refetch } = useGetServiceQuery(id);

  useEffect(()=>{
    refetch(id)
  },[id, refetch])

  useTitle("Service Details");

  console.log("service",data);
  return (
    <ViewCard
      isImage={true}
      image_url={data ? data.service.image : ""}
      title={"Service Details"}
      data={data?.service && {...data.service,category: data.service.category? data.service.category.categoryName : <strong>Category not set</strong>}}
      setModalShow={setModalShow}
      keyProps={keyProps}
      reducerProps={{ error, isLoading, isSuccess }}
    >
      <EditService show={modalShow} onHide={() => setModalShow(false)} />
      {!modalShow && <ToastContainer />}
    </ViewCard>
  );
};

export default View;
