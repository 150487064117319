import { useState } from "react";
import { useParams } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import { useTitle, ViewCard } from "../../components";
import { useGetCategoryQuery } from "../../store";
import EditCategory from "./Edit";

const keyProps = {
  "Category ID": "id",
  "Category Name": "categoryName",
  "Created At": "createdAt",
  "Last Update": "updatedAt",
};

const View = () => {
  const { id } = useParams(); // Truck/:id

  const [modalShow, setModalShow] = useState(false);
  const {
    data,
    isLoading,
    error,
    isSuccess,
  } = useGetCategoryQuery(id);

  useTitle("Category Details");
 
  
  // if(isLoading) {
  //   return <Skeleton height={200} />
  // }

  return (
    <ViewCard
      title={"Category Details"}
      data={data?.category}
      setModalShow={setModalShow}
      keyProps={keyProps}
      reducerProps={{ error, isLoading, isSuccess }}
    >
      <EditCategory show={modalShow} onHide={() => setModalShow(false)} />
      {!modalShow && <ToastContainer />}
    </ViewCard>
  );
};

export default View;
