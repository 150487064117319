import axios from "axios";

// const baseUrl = process.env.REACT_APP_API_BASE_URL;
const axiosInstance = axios.create({
  // baseURL: "http://localhost:5000", // localhost
  baseURL: "https://api.bizpitch.ca", // hosted
  // baseURL: baseUrl, // hosted
});

export default axiosInstance;
