import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { adminBaseUrl } from "../routes";
import { loginUser } from "./queries";

const adminAuthApi = createApi({
  reducerPath: "admin-auth",
  baseQuery: fetchBaseQuery({
    baseUrl: adminBaseUrl,
  }),
  endpoints: (builder) => ({
    //login admin
    login: builder.mutation({
      providesTags: ["AdminLogin"],
      query: loginUser,
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const res = await queryFulfilled;
          if (res.data) {
            localStorage.setItem("user", JSON.stringify(res.data.admin));
            localStorage.setItem("token", JSON.stringify(res.data.token));
          }
        } catch (err) {
          console.log(err.error.data.error.message);
        }
      },
    }),
  }),
});

//logout user
export const logoutUser = () => {
  adminAuthApi.util.invalidateTags(["AdminLogin"]);
  localStorage.removeItem("user");
  localStorage.removeItem("token");
};

export const {
  reducer: adminAuthReducer,
  reducerPath: adminAuthReducerPath,
  middleware: adminAuthMiddleware,
  useLoginMutation,
  endpoints: adminAuthEndpoints,
} = adminAuthApi;
