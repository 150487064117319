import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import {
  categoryMiddleware,
  categoryReducer,
  categoryReducerPath,
} from "./api/category";
import {
  serviceMiddleware,
  serviceReducer,
  serviceReducerPath,
} from "./api/service";
import {
  adminAuthMiddleware,
  adminAuthReducer,
  adminAuthReducerPath,
  adminReducer,
  adminMiddleware,
  adminReducerPath,
  adminSliceReducer,
} from "./api/admin";
import {
  sharedMiddleware,
  sharedReducer,
  sharedReducerPath,
} from "./api/shared";
import { userMiddleware, userReducer, userReducerPath } from "./api/user";
import {
  providerReducerPath,
  providerMiddleware,
  providerReducer,
} from "./api/provider";
import {
  contentMiddleware,
  contentReducer,
  contentReducerPath,
} from "./api/content";
import {
  bannerMiddleware,
  bannerReducer,
  bannerReducerPath,
} from "./api/banner";
import {
  enquiryReducer,
  enquiryReducerPath,
  enquiryMiddleware,
} from "./api/enquiry";
import {
  deleteUserApi,
  privacyApi,
  submitQueryApi,
  tandcApi,
} from "./api/public"

export const store = configureStore({
  reducer: {
    [adminAuthReducerPath]: adminAuthReducer,
    [adminReducerPath]: adminReducer,
    [sharedReducerPath]: sharedReducer,
    [categoryReducerPath]: categoryReducer,
    [serviceReducerPath]: serviceReducer,
    [userReducerPath]: userReducer,
    adminSlice: adminSliceReducer,
    [providerReducerPath]: providerReducer,
    [contentReducerPath]: contentReducer,
    [bannerReducerPath]: bannerReducer,
    [enquiryReducerPath]: enquiryReducer,
    [tandcApi.reducerPath]: tandcApi.reducer,
    [privacyApi.reducerPath]: privacyApi.reducer,
    [deleteUserApi.reducerPath]: deleteUserApi.reducer,
    [submitQueryApi.reducerPath]: submitQueryApi.reducer
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(categoryMiddleware)
      .concat(serviceMiddleware)
      .concat(adminAuthMiddleware)
      .concat(adminMiddleware)
      .concat(sharedMiddleware)
      .concat(userMiddleware)
      .concat(providerMiddleware)
      .concat(contentMiddleware)
      .concat(bannerMiddleware)
      .concat(enquiryMiddleware)
      .concat(tandcApi.middleware, privacyApi.middleware, deleteUserApi.middleware, submitQueryApi.middleware);
  },
});

setupListeners(store.dispatch);

// exports for
// category
export {
  useCreateCategoryMutation,
  useDeleteCategoryMutation,
  useGetAllCategoriesQuery,
  useGetCategoryQuery,
  useUpdateCategoryMutation,
} from "./api/category";

// service
export {
  useCreateServiceMutation,
  useDeleteServiceMutation,
  useGetAllServicesQuery,
  useGetServiceQuery,
  useUpdateServiceMutation,
} from "./api/service";

//admin
export {
  useLoginMutation,
  logoutUser,
  setAuth,
  clearAuth,
  useGetSummaryQuery,
  useGetProfileQuery,
  useUpdateProfileMutation,
} from "./api/admin";

//shared
export { useUploadImageMutation } from "./api/shared";

//user
export {
  useDeleteUserMutation,
  useGetAllUsersQuery,
  useGetUserByIdQuery,
  useUpdateUserMutation,
  useLockUnlockUserMutation,
} from "./api/user";

//provider
export {
  useDeleteProviderMutation,
  useGetAllProvidersQuery,
  useGetProviderByIdQuery,
  useUpdateProviderMutation,
  useLockUnlockProviderMutation,
} from "./api/provider";

//content
export {
  useGetContentQuery,
  useDeleteContentMutation,
  useCreateContentMutation,
  useUpdateContentMutation,
} from "./api/content";

//banners
export {
  useGetAllBannersQuery,
  useGetBannerByIdQuery,
  useCreateBannerMutation,
  useRemoveBannerMutation,
  useUpdateBannerMutation,
} from "./api/banner";

//enquiry
export { useGetRecentEnquiriesQuery } from "./api/enquiry";

//public
export { useGetPrivacyQuery, useGetTandCQuery, useDeleteUser, useSubmitQuery } from "./api/public"
