import { ToastContainer, toast } from "react-toastify";
import {
  CustomDialog,
  CustomTable,
  DeleteButton,
  MessageBox,
  MotionDiv,
  ViewButton,
  useTitle,
} from "../../components";
import { useEffect, useState } from "react";
import {
  useDeleteProviderMutation,
  useGetAllProvidersQuery,
  useLockUnlockProviderMutation,
} from "../../store";
import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { toastOptions } from "../../utils/error";
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";


export default function User() {
  useTitle("Provider");
  const [, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState();
  const [idToLock, setIdToLock] = useState({ id: null, isLocked: false });
  const [idToDelete, setIdToDelete] = useState(null);
  const navigate = useNavigate();

  const {
    data: providers,
    isLoading: isProvidersLoading,
    isError: isProvidersError,
    error: providersError,
    refetch,
  } = useGetAllProvidersQuery({
    keyword: searchInput,
    currentPage,
    resultPerPage,
  });
  console.log("............providers", providers);
  const [
    removeProvider,
    {
      isSuccess: isProviderDeleted,
      isError: isProviderDeleteError,
      error: providerDeleteError,
      isLoading: isProviderDeleteLoading
    },
  ] = useDeleteProviderMutation();

  const [
    lockUnlockProvider,
    {
      isSuccess: isLockUnlockProviderSuccess,
      isError: isLockUnlockError,
      error: lockUnlockError,
      isLoading: isLockUnlockLoading,
    },
  ] = useLockUnlockProviderMutation();

  const curPageHandler = (p) => setCurrentPage(p);

  const column = [
    "S.No",
    "Provider Id",
    "Profile Image",
    "Fullname ",
    "Email",
    "Phone",
    // "On Hold",
    "Verifed",
    "Locked",
    "Actions",
  ];
  const numOfPages = Math.ceil(providers?.count / resultPerPage);
  const skip = resultPerPage * (currentPage - 1);
  console.log("providers", providers);

  const handleLockUnlockState = (id, isLocked) => {
    setIdToLock({
      id,
      isLocked,
    });
  };
  const handleModalClose = () => setIdToLock({ id: null, isLocked: false });

  const handleDeleteModalOpen = (id) => setIdToDelete(id);
  const handleDeleteModalClose = () => setIdToDelete(null);

  useEffect(() => {
    if (isProviderDeleteError) {
      toast.error(providerDeleteError?.data?.error.message, toastOptions);
    }

    if (isProviderDeleted) {
      toast.success("Provider Deleted Successfully.", toastOptions);
      handleDeleteModalClose()
    }
  }, [isProviderDeleted, isProviderDeleteError, providerDeleteError]);

  useEffect(() => {
    refetch({ keyword: searchInput, currentPage, resultPerPage });
  }, [currentPage, refetch, resultPerPage, searchInput]);

  useEffect(() => {
    if (isProviderDeleted) {
      refetch({ keyword: searchInput, currentPage, resultPerPage });
    }
  }, [isProviderDeleted, currentPage, refetch, resultPerPage, searchInput]);

  useEffect(() => {
    if (isLockUnlockError) {
      toast.error(lockUnlockError.data.error.message, toastOptions);
    }

    if (isLockUnlockProviderSuccess) {
      toast.success("Provider State Updated.", toastOptions);
      handleModalClose();
    }
  }, [isLockUnlockProviderSuccess, lockUnlockError, isLockUnlockError]);

  useEffect(() => {
    if (isLockUnlockProviderSuccess) {
      refetch({ keyword: searchInput, currentPage, resultPerPage });
    }
  }, [
    currentPage,
    refetch,
    resultPerPage,
    searchInput,
    isLockUnlockProviderSuccess,
  ]);

  return (
    <MotionDiv>
      {isProvidersError ? (
        <MessageBox variant="danger">
          {providersError.data.error.message}
        </MessageBox>
      ) : (
        <CustomTable
          loading={isProvidersLoading}
          column={column}
          rowNo={resultPerPage}
          rowProps={{ setResultPerPage }}
          paging={numOfPages > 0}
          pageProps={{ numOfPages, currentPage }}
          pageHandler={curPageHandler}
          search={true}
          searchProps={{ searchInput, setSearchInput, setQuery }}
          isTitle="true"
          title="Providers"
        >
          {providers?.providers &&
            providers.providers.map((provider, i) => {
              return (
                <tr key={i} className="odd">
                  <td className="text-center">{skip + i + 1}</td>
                  <td className="text-center">{provider.id}</td>
                  <td>
                    <img
                      className="td-img"
                      src={provider.profileImage}
                      alt="img"
                    />
                  </td>
                  <td>{provider.fullname}</td>
                  <td>{provider.email}</td>
                  <td>{provider.mobile_no}</td>
                  {/* <td className="text-center">
                    {provider.onHold ? "yes" : "no"}
                  </td> */}
                  <td className="text-center">
                    {provider.isVerified ? (
                      <FaCheck className="green" />
                    ) : (
                      <ImCross className="red" />
                    )}
                  </td>
                  <td className="">
                    {provider.isLocked ? (
                      <button
                        className="rounded bg-warning px-2 text-white text-center"
                        name="unlock"
                        onClick={() =>
                          handleLockUnlockState(provider.id, provider.isLocked)
                        }
                      >
                        <FaLockOpen />
                      </button>
                    ) : (
                      <button
                        className="rounded bg-danger px-2 text-white text-center"
                        name="lock"
                        onClick={() =>
                          handleLockUnlockState(provider.id, provider.isLocked)
                        }
                      >
                        <FaLock />
                      </button>
                    )}
                  </td>

                  <td>
                    <ViewButton
                      onClick={() =>
                        navigate(`/admin/view/providers/${provider.id}`)
                      }
                    />
                    <DeleteButton onClick={() => handleDeleteModalOpen(provider.id)} />
                  </td>
                </tr>
              );
            })}
        </CustomTable>
      )}

      <CustomDialog
        show={idToLock.id}
        onHide={handleModalClose}
        title={"Are you sure?"}
        loading={isLockUnlockLoading}
        content={`You want to ${
          idToLock.isLocked ? "Unlock" : "Lock"
        } this provider?`}
        onSubmit={(e) => {
          e.preventDefault();
          lockUnlockProvider(idToLock.id);
        }}
      />
      <CustomDialog
        show={idToDelete}
        onHide={handleDeleteModalClose}
        title={"Are you sure?"}
        loading={isProviderDeleteLoading}
        content={`You want to delete this provider?`}
        onSubmit={(e) => {
          e.preventDefault();
          removeProvider(idToDelete)
        }}
      />
      <ToastContainer />
    </MotionDiv>
  );
}
