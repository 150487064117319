import React, { useEffect, useState } from "react";
import { EditForm } from "../components";
import { UploadFileComp } from "../components/layout";
import { useGetProfileQuery, useUpdateProfileMutation } from "../store";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";

export default function EditUserModel(props) {
  const { data } = useGetProfileQuery();
  const [updateProfile, { error, isLoading, isSuccess }] =
    useUpdateProfileMutation();

  const userAttr = [
    {
      type: "text",
      props: {
        label: "Fullname",
        name: "fullname",
        required: true,
      },
    },
  ];

  const [file, setFile] = useState(null);
  const [value, setValue] = useState(null);
  const [info, setInfo] = useState({
    fullname: null,
    mobile_no: null,
    country_code: null,
  });

  useEffect(() => {
    if (data?.user) {
      setInfo({
        fullname: data.user.fullname,
        mobile_no: data.user.mobile_no,
        country_code: data.user.country_code,
      });
      setValue(data.user.country_code + data.user.mobile_no);
      setFile(data.user.profileImage);
    }
  }, [data?.user]);

  const submitHandler = async (e) => {
    e.preventDefault();
    const data = {
      profileImage: file,
      fullname: info.fullname,
      country_code: info.country_code,
      mobile_no: info.mobile_no,
    };
    console.log("....data", data);
    updateProfile(data);
  };
  console.log("........error", error);
  return (
    <EditForm
      {...props}
      title="Update Profile"
      data={info}
      setData={setInfo}
      inputFieldProps={userAttr}
      submitHandler={submitHandler}
      target="/"
      successMessage="Profile Updated Successfully! Redirecting..."
      reducerProps={{ loadingUpdate: isLoading, error, success: isSuccess }}
    >
      <div>
        <div>
          <p style={{ marginBottom: "0.5rem" }}>Mobile No.</p>
          <PhoneInput
            className="form-control mb-3"
            placeholder="Enter phone number"
            value={value}
            onChange={(e) => {
              if (e) {
                const countryCode = parsePhoneNumber(e)?.countryCallingCode;
                const mobileNum = parsePhoneNumber(e)?.nationalNumber;
                setInfo({
                  ...info,
                  country_code: "+" + countryCode,
                  mobile_no: mobileNum,
                });
                setValue("+" + countryCode + mobileNum);
              }
            }}
          />
        </div>
        <div>
          <UploadFileComp
            label="Profile Image"
            fileType={"image"}
            accept="image/*"
            file={file}
            setFile={setFile}
          />
        </div>
      </div>
    </EditForm>
  );
}
