//login user
export const loginUser = (user) => {
  return {
    url: "/login",
    method: "POST",
    body: {
      email: user.email,
      password: user.password,
    },
  };
};

//get admin summary
export const getSummary = () => {
  return {
    url: "/summary",
    mehtod: "GET",
  };
};

//get admin profile
export const getProfile = () => {
  return {
    url: "/profile",
    mehtod: "GET",
  };
};

//update admin profile
export const updateProfile = (data) => {
  return {
    url: "/profile",
    method: "PUT",
    body: { ...data },
  };
};
