import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { userBaseUrl } from "../routes";
import { deleteUser, getAllUsers, getUserById, lockUnlockUser, updateUser } from "./queries";
import { headerWithAuthToken } from "../headers";

const userApi = createApi({
  reducerPath: "user",
  baseQuery: fetchBaseQuery({
    baseUrl: userBaseUrl,
    prepareHeaders: headerWithAuthToken,
  }),
  endpoints: (builder) => ({
    //get all users
    getAllUsers: builder.query({
      query: getAllUsers,
    }),

    //get user by id
    getUserById: builder.query({
      query: getUserById,
    }),

    // update user
    updateUser: builder.mutation({
      query: updateUser,
    }),

    //delete user
    deleteUser: builder.mutation({
      query: deleteUser,
    }),

    //lock/unlock user
    lockUnlockUser: builder.mutation({
      query: lockUnlockUser,
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useGetUserByIdQuery,
  useUpdateUserMutation,
  useLockUnlockUserMutation,
  useDeleteUserMutation,
  reducer: userReducer,
  reducerPath: userReducerPath,
  middleware: userMiddleware,
} = userApi;
