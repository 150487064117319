//get recent enquiries
export const getRecentEnquiries = (query) => {
  return {
    url: "/",
    method: "GET",
    params: {
      currentPage: query.currentPage,
      resultPerPage: query.resultPerPage,
      startDate: query.startDate,
      endDate: query.endDate
    },
  };
};
