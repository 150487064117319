import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { enquiryBaseUrl } from "../routes";

import { headerWithAuthToken } from "../headers";
import { getRecentEnquiries } from "./queries";

const enquiryApi = createApi({
  reducerPath: "enquiry",
  baseQuery: fetchBaseQuery({
    baseUrl: enquiryBaseUrl,
    prepareHeaders: headerWithAuthToken,
  }),
  endpoints: (builder) => ({
    //get recent enquiries
    getRecentEnquiries: builder.query({
      query: getRecentEnquiries,
    }),
  }),
});

export const {
  useGetRecentEnquiriesQuery,
  reducer: enquiryReducer,
  reducerPath: enquiryReducerPath,
  middleware: enquiryMiddleware,
} = enquiryApi;
