import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
import { CustomDialog, useTitle, ViewCard } from "../../components";
import {
  useGetProviderByIdQuery,
  useUpdateProviderMutation,
} from "../../store";
import { Card, Col, Form, Row } from "react-bootstrap";
import { FaInstagramSquare } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { CgWebsite } from "react-icons/cg";
import Skeleton from "react-loading-skeleton";
import { CiCircleInfo } from "react-icons/ci";
import { toastOptions } from "../../utils/error";
import { FaCheck, FaEye } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { IoMdDownload } from "react-icons/io";
import EditUser from "../user/Edit";
import EditProvider from "./Edit";

const keyProps = {
  "Provider ID": "id",
  Fullname: "fullname",
  Email: "email",
  "Mobile No.": "mobile_no",
  "Is Verified": "isVerified",
  "Business Name": "buisness_name",
  "Identification Number": "id_no",
  "Business Location": "buisness_location",
  "Created At": "createdAt",
  "Last Update": "updatedAt",
};

const View = () => {
  const { id } = useParams();

  const [info, setInfo] = useState({ onHold: null, id: null });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const { data, isLoading, error, isSuccess, refetch } =
    useGetProviderByIdQuery(id);
  console.log("..provider", data?.provider);
  const [
    updateProvider,
    {
      isLoading: isUpdateProviderLoading,
      isSuccess: isUpdateProviderSucess,
      error: updateProviderError,
      isError: isUpdateProviderError,
    },
  ] = useUpdateProviderMutation();

  //get number of opening days
  const getNumberOpeningDays = (data) => {
    let count = 0;
    data.map((t) => t.is_open && count++);
    return count;
  };

  const getWeekDayName = (day) => {
    const weekdays = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    return weekdays[day];
  };

  const handleModalClose = () => setIsModalOpen(false);
  const handleModalOpen = () => setIsModalOpen(true);

  const submitHandler = (e) => {
    if (info.onHold === null || info.id === null) return;

    if (isModalOpen) {
      updateProvider(info);
      handleModalClose();
    } else {
      handleModalOpen();
      setInfo({ ...info, onHold: e.target.checked });
    }
  };

  useEffect(() => {
    if (isUpdateProviderError) {
      toast.error(updateProviderError.data.error.message, toastOptions);
    }

    if (isUpdateProviderSucess) {
      toast.success(
        `Sucessfully ${!info.onHold ? "APPROVED" : "DISAPPROVED"
        } this provider...`,
        toastOptions
      );
      refetch(id);
    }
  }, [
    isUpdateProviderSucess,
    updateProviderError,
    isUpdateProviderError,
    info.onHold,
    id,
    refetch,
  ]);

  // useEffect(() => {
  //   refetch(id);
  // }, [id, refetch]);

  useEffect(() => {
    if (data && data?.provider.id === id) {
      setInfo({
        id: data.provider.id,
        onHold: data.provider.onHold,
      });
    }
  }, [data, id]);

  useTitle("Provider Details");

  return (
    <ViewCard
      isImage={true}
      // isEdit={false}
      image_url={data?.provider ? data?.provider?.profileImage : ""}
      title={"Provider Details"}
      setModalShow={setModalShow}
      data={
        data?.provider && {
          ...data?.provider,
          mobile_no: `${data?.provider?.country_code} ${data?.provider?.mobile_no}`,
        }
      }
      keyProps={keyProps}
      reducerProps={{ error, isLoading, isSuccess }}
      actionSection={
        <Card.Body className="my-3">
          <Row className="mb-3">
            <Card.Header className="d-flex align-items-center">
              <Card.Title>Document Verification</Card.Title>
              {/* <CiCircleInfo className="ms-2 fs-5"/> */}
              {/* <span>Here admin can do verification of provided documents</span> */}
            </Card.Header>
          </Row>
          <Row>
            <Col md={6} lg={4} className="">
              <p className="mb-1">
                <strong>Uploaded Document</strong>
              </p>
              <p>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <div className="">
                    {data?.provider.document ? (
                      <>
                        <iframe
                          src={data?.provider.document}
                          width={"100%"}
                          title="pdf"
                        />
                        <div className="d-flex justify-content-center">
                          <a
                            href={data?.provider.document}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <button className="btn btn-primary">
                              <FaEye size={20} />
                            </button>
                          </a>
                          {/* <button
                        onClick={() => {
                          const fileUrl = data?.provider.document;
                          const filename = "document.pdf";
                          const url = window.URL.createObjectURL(new Blob([fileUrl],{type:"application/pdf"}));
                          const downloadLink = document.createElement("a");
                          downloadLink.href = url;
                          downloadLink.download = filename;
                          document.body.appendChild(downloadLink);
                          downloadLink.click();
                          // document.body.removecChild(downloadLink);
                          // window.URL.revokeObjectURL(downloadLink)
                        }}
                        className="btn btn-secondary"
                        >
                        <IoMdDownload size={20} />
                      </button> */}
                        </div>
                      </>
                    ) : (
                      <span>Document is not provided</span>
                    )}
                  </div>
                )}
              </p>
            </Col>
            {/* <Col md={6} lg={4} className="">
              <p className="mb-1 d-flex align-items-center">
                <strong>Status</strong>
                {isUpdateProviderLoading || isLoading ? (
                  <Skeleton className="ms-2" width={"8rem"} />
                ) : (
                  <span className="ms-2" style={{ fontSize: "0.8rem" }}>
                    [{" "}
                    {data.provider.onHold ? (
                      <span>
                        <ImCross className="red" /> Not Approved
                      </span>
                    ) : (
                      <span>
                        <FaCheck className="green" /> Approved
                      </span>
                    )}{" "}
                    ]
                  </span>
                )}
              </p>
              <p>
                {isLoading || isUpdateProviderLoading ? (
                  <Skeleton />
                ) : (
                  <Form.Group className="d-flex gap-2">
                    <Form.Check
                      checked={info.onHold}
                      onChange={submitHandler}
                      className="checkbox"
                      type="checkbox"
                    />
                    <Form.Label>
                      {info.onHold
                        ? "Check this box to approve submitted documents"
                        : "Check this box to disapprove submitted documents"}
                    </Form.Label>
                  </Form.Group>
                )}
              </p>
            </Col> */}
          </Row>
        </Card.Body>
      }
    >
      {/* <EditProvider show={modalShow} onHide={() => setModalShow(false)} />
      {!modalShow && <ToastContainer />} */}

      {/* other details */}
      <div className="mt-4">
        <Card.Header>
          <Card.Title>Other Details</Card.Title>
        </Card.Header>
      </div>
      <Card.Body>
        {/* Posts */}
        <Col className="p-0 m-0">
          <p>
            {data?.provider?.posts?.length ? (
              <p className="fw-medium fs-5 mb-0">
                Posts ({data?.provider?.posts.length})
              </p>
            ) : (
              <span>
                <p className="fw-medium fs-5 mb-0">
                  Posts ({data?.provider?.posts.length})
                </p>
                <p className="mb-0">No Posts Added</p>
              </span>
            )}
          </p>
          <div
            style={{ maxHeight: "30rem" }}
            className="col-12 d-flex gap-1 gap-md-1 overflow-auto flex-wrap position-relative"
          >
            {data?.provider?.posts.map((post) => {
              return (
                <img
                  className="col-3 col-md-2 rounded-3"
                  style={{ height: "10rem" }}
                  alt={post.url}
                  key={post.id}
                  src={post.url}
                />
              );
            })}
          </div>
        </Col>
        {/* Timings */}
        <Col className="mb-2 mt-5">
          <p>
            <p className="fw-medium fs-5">
              Timings{" "}
              <span className="fs-6">
                ( open for{" "}
                {data?.provider?.time?.length
                  ? getNumberOpeningDays(data?.provider?.time)
                  : 0}{" "}
                day(s) )
              </span>
            </p>
          </p>
          <div className="col-12 d-flex gap-3 flex-wrap">
            {data?.provider?.time?.length
              ? data?.provider?.time.map((t) => {
                return (
                  <div className="border flex-grow-1 rounded-3 px-2 py-1">
                    <p className="d-flex gap-1 rounded-3 mb-0 text-secondary">
                      <strong>{getWeekDayName(t.weekday)}</strong>
                      <span>
                        {t.is_open ? (
                          <label className="text-green fw-medium">open</label>
                        ) : (
                          <label className="text-red fw-medium">closed</label>
                        )}
                      </span>
                    </p>
                    <p className="mb-0">
                      {t.from.slice(0, -3)} to {t.to.slice(0, -3)}
                    </p>
                  </div>
                );
              })
              : "Timings are not provided"}
          </div>
        </Col>
        {/* provider video */}
        <Col className="mb-2 mt-5">
          <p>
            <p className="fw-medium fs-5">Provider Video</p>
          </p>
          <div className="">
            {data?.provider?.video?.url ? (
              // <iframe  src={data.provider.video.url} title="video" />
              <video src={data.provider.video.url} controls className="col-2" />
            ) : (
              "Nothing Here"
            )}
          </div>
        </Col>

        {/* own services */}
        <Col className="mb-2 mt-5">
          <p>
            <p className="fw-medium fs-5">Own Services</p>
          </p>
          <div className="d-flex gap-2">
            {data?.provider?.ownService && data?.provider?.ownService.length
              ? data?.provider?.ownService.map((service) => {
                return (
                  <div className="border flex-grow-1 rounded-3 px-2 py-1">
                    <p className="d-flex gap-1 rounded-3 mb-0 text-secondary">
                      <strong>{service.serviceTitle?.title} -</strong>
                      <span>
                        <label className="fw-medium text-black">
                          ${service.charge}
                        </label>
                      </span>
                    </p>
                    <p className="mb-0">{service.desc}</p>
                  </div>
                );
              })
              : "No Services Listed"}
          </div>
        </Col>

        {/* socials */}
        <Col className="mb-2 mt-5">
          <p>
            <p className="fw-medium fs-5">Socials</p>
          </p>
          <div>
            {/* instagram */}
            {data?.provider?.instagram && (
              <p className="d-flex align-items-center gap-2">
                <FaInstagramSquare /> Instagram -{" "}
                <a
                  href={data?.provider.instagram}
                  target="_blank"
                  rel="noreferrer"
                >
                  {data?.provider.instagram}
                </a>
              </p>
            )}

            {/* facebook */}
            {data?.provider?.facebook && (
              <p className="d-flex align-items-center gap-2">
                <FaFacebook /> Facebook -{" "}
                <a
                  href={data?.provider.facebook}
                  target="_blank"
                  rel="noreferrer"
                >
                  {data?.provider.facebook}
                </a>
              </p>
            )}

            {/* website */}
            {data?.provider?.website && (
              <p className="d-flex align-items-center gap-2">
                <CgWebsite /> Website -{" "}
                <a
                  href={data?.provider.website}
                  target="_blank"
                  rel="noreferrer"
                >
                  {data?.provider.website}
                </a>
              </p>
            )}

            {!data?.provider.instagram &&
              !data?.provider.facebook &&
              !data?.provider.website && <p>No social links provided</p>}
          </div>
        </Col>
      </Card.Body>
      <CustomDialog
        show={isModalOpen}
        onHide={handleModalClose}
        title={"Are you sure?"}
        loading={isUpdateProviderLoading}
        content={`You want to ${info.onHold ? "DISAPPROVE" : "APPROVE"
          } this provider?`}
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler();
        }}
      />
      <EditProvider show={modalShow} onHide={() => setModalShow(false)} />
    </ViewCard>
  );
};

export default View;
