import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { bannerBaseUrl } from "../routes";
import { headerWithAuthToken } from "../headers";
import {
  createBanner,
  getAllBanners,
  getBannerById,
  removeBanner,
  updateBanner,
} from "./queries";

const temporaryBaseUrl = "https://api.bizpitch.ca/api"

const bannerApi = createApi({
  reducerPath: "banner",
  baseQuery: fetchBaseQuery({
    // baseUrl: bannerBaseUrl,
    baseUrl: temporaryBaseUrl,
    prepareHeaders: headerWithAuthToken,
  }),
  endpoints: (builder) => ({
    // get all banners
    getAllBanners: builder.query({
      query: getAllBanners,
    }),

    //get banner by id
    getBannerById: builder.query({
      query: getBannerById,
    }),

    //create banner
    createBanner: builder.mutation({
      query: createBanner,
    }),

    //update banner
    updateBanner: builder.mutation({
      query: updateBanner,
    }),

    //delete banner
    removeBanner: builder.mutation({
      query: removeBanner,
    }),
  }),
});

export const {
  useGetAllBannersQuery,
  useGetBannerByIdQuery,
  useCreateBannerMutation,
  useUpdateBannerMutation,
  useRemoveBannerMutation,
  reducer: bannerReducer,
  reducerPath: bannerReducerPath,
  middleware: bannerMiddleware,
} = bannerApi;
