import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Row,
    Spinner,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { FaEnvelope, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import { useTitle } from "../../components";
import { toastOptions } from "../../utils/error";
import { useDispatch } from "react-redux";
import { useSubmitQuery } from "../../store";

const ContactUs = () => {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [query, setQuery] = useState("");
    const [
        submitQuery,
        {
            isLoading: isSubmitLoading,
            error: submitError,
            isError: isSubmitError,
            isSuccess: isSubmitSuccess,
        },
    ] = useSubmitQuery();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const submitHandler = async (e) => {
        e.preventDefault();
        if (!email || !name || !query) return;

        submitQuery({ email, name, query });
    };

    useEffect(() => {
        if (isSubmitError) {
            toast.error(submitError?.data.error.message, toastOptions);
        }
    }, [submitError?.data, isSubmitError]);

    useEffect(() => {
        if (isSubmitSuccess) {
            toast.success("Query Submitted successfully", toastOptions);
            setEmail("");
            setName("");
            setQuery("");
        }
    }, [isSubmitSuccess, navigate]);

    useTitle("Submit Query");
    return (
        <Container fluid className="p-0 vh-100 f-center flex-column login-page">
            <div className="login-logo">
                <Link to="/" className="text-center">
                    <b>BIZ PITCH</b>
                </Link>
            </div>

            <Card className="login-box">
                <Card.Body>
                    <p className="text-center">Submit your query here</p>
                    <Form onSubmit={submitHandler}>
                        {/* Name */}
                        <Form.Group controlId="name" className="input-group mb-3">
                            <Form.Control
                                placeholder="Name"
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </Form.Group>
                        {/* Email */}
                        <Form.Group controlId="email" className="input-group mb-3">
                            <Form.Control
                                placeholder="Email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </Form.Group>
                        {/* Query */}
                        <Form.Group controlId="query" className="input-group mb-3">
                            <Form.Control
                                as="textarea"
                                placeholder="Query"
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Row>
                            <Col>
                                {isSubmitLoading ? (
                                    <Button type="submit" className="float-sm-end" disabled>
                                        <Spinner animation="border" size="sm" />
                                    </Button>
                                ) : (
                                    <Button
                                        id="delete_submit"
                                        type="submit"
                                        className="float-sm-end"
                                    >
                                        Submit
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </Form>
                    <ToastContainer />
                </Card.Body>
            </Card>
        </Container>
    );
}
export default ContactUs;
