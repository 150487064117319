import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EditForm, UploadFileComp } from "../../components";
import { useGetAllCategoriesQuery, useGetServiceQuery, useUpdateServiceMutation } from "../../store";

export default function EditService(props) {
  const { id } = useParams();

  const {
    data: categories,
    isLoading: isCategoryLoading,
    // isError: isCategoryError,
    // error: categoryError,
  } = useGetAllCategoriesQuery({
    keyword: "",
  });

  const selectOptions = categories?.categories.map((category) => {
    return { [category.id]: category.categoryName };
  });


  const [info, setInfo] = useState({ title: "", image: "", categoryId: null });
  const [file, setFile] = useState(null);

   
  const serviceAttr = [
    {
      type: "text",
      col: 12,
      props: {
        label: "Title",
        name: "title",
        maxLength: 50,
        minLength: 4,
        required: true,
      },
    },
    {
      type: "select",
      col: 12,
      props: {
        label: "Category",
        name: "categoryId",
        required: true,
        options: selectOptions,
        placeholder: "Select category",
        value:info.categoryId,
        disabled: isCategoryLoading
      },
    },
  ];

  const [
    updateService,
    {
      isLoading: isUpdateServiceLoading,
      isSuccess: isUpdateServiceSucess,
      error: updateServiceError,
    },
  ] = useUpdateServiceMutation();
  const { data } = useGetServiceQuery(id);

  useEffect(() => {
    if (data?.service && data?.service.id === Number(id)) {
      setInfo({
        title: data.service.title,
        id: Number(data.service.id),
        categoryId: data.service.category?.id,
        image: data.service.image,
      });
      setFile(data.service.image)
    }
  }, [id, data?.service]);

  const submitHandler = async (e) => {
    e.preventDefault();
    const data = {
      ...info,
      image: file,
    };
    updateService(data);
  };

  return (
    <EditForm
      {...props}
      title="Edit Service"
      data={info}
      setData={setInfo}
      inputFieldProps={serviceAttr}
      submitHandler={submitHandler}
      target="/admin/services"
      successMessage="Services Updated Successfully! Redirecting..."
      reducerProps={{
        loadingUpdate: isUpdateServiceLoading,
        error: updateServiceError,
        success: isUpdateServiceSucess,
      }}
    >
      <div>
        {/* <label>Image</label> */}
        <UploadFileComp label="Image" fileType={"image"} accept="image/*" file={file} setFile={setFile} />
      </div>
    </EditForm>
  );
}
