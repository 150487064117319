import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { contentBaseUrl } from "../routes";
import { headerWithAuthToken } from "../headers";
import { createContent, deleteContent, getContent, updateContent } from "./queries";

const contentApi = createApi({
  reducerPath: "content",
  baseQuery: fetchBaseQuery({
    baseUrl: contentBaseUrl,
    prepareHeaders: headerWithAuthToken,
  }),
  endpoints: (builder) => ({
    //get content
    getContent: builder.query({
      query: getContent,
    }),

    //delete content
    deleteContent: builder.mutation({
      query: deleteContent,
    }),

    //create content
    createContent: builder.mutation({
      query: createContent,
    }),

    //update content
    updateContent: builder.mutation({
      query: updateContent,
    }),
  }),
});

export const {
  useGetContentQuery,
  useDeleteContentMutation,
  useCreateContentMutation,
  useUpdateContentMutation,
  reducer: contentReducer,
  reducerPath: contentReducerPath,
  middleware: contentMiddleware,
} = contentApi;
