import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import { useTitle, ViewCard } from "../../components";
import { useGetUserByIdQuery } from "../../store";
import EditUser from "./Edit";

const keyProps = {
  Fullname: "fullname",
  // Gender: "gender",
  "Is Verified": "isVerified",
  "Mobile No.": "mobile_no",
  "Created At": "createdAt",
  "Last Update": "updatedAt",
};

const View = () => {
  const { id } = useParams();

  const [modalShow, setModalShow] = useState(false);
  const { data, isLoading, error, isSuccess, refetch } =
    useGetUserByIdQuery(id);

  useEffect(() => {
    refetch(id);
  }, [id, refetch]);

  useTitle("User Details");
  return (
    <ViewCard
      isImage={true}
      image_url={data ? data.user.profileImage : ""}
      title={"User Details"}
      data={data?.user && {
        ...data?.user,
        mobile_no: `${data?.user?.country_code} ${data?.user?.mobile_no}`,
      }}
      setModalShow={setModalShow}
      keyProps={keyProps}
      reducerProps={{ error, isLoading, isSuccess }}
    >
      <EditUser show={modalShow} onHide={() => setModalShow(false)} />
      {!modalShow && <ToastContainer />}
    </ViewCard>
  );
};

export default View;
