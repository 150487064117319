import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EditForm, UploadFileComp } from "../../components";
import { useGetBannerByIdQuery, useUpdateBannerMutation } from "../../store";
import { toastOptions } from "../../utils/error";
import { toast } from "react-toastify";

export default function EditBanner(props) {
  const { id } = useParams();

  const [file, setFile] = useState(null);
  const [info, setInfo] = useState({ link: null, client_name: null });
  const [bannerId, setBannerId] = useState(null);
  const [UpdateCompError, setUpdateCompError] = useState(null);

  const bannerAttr = [
    {
      type: "text",
      col: 12,
      props: {
        label: "Client Name",
        name: "client_name",
        placeholder: "Enter client name",
      },
    },
    {
      type: "text",
      col: 12,
      props: {
        label: "Navigation Link",
        name: "link",
        placeholder: "Enter banner navigation link",
      },
    },
  ];

  const [
    updateBanner,
    {
      isLoading: isUpdateBannerLoading,
      isSuccess: isUpdateBannerSucess,
      error: updateBannerError,
    },
  ] = useUpdateBannerMutation();
  const { data } = useGetBannerByIdQuery(id);

  useEffect(() => {
    if (data?.banner && data?.banner.id === Number(id)) {
      setBannerId(data.banner.id);
      setFile(data.banner.url);
      setInfo({ ...info, link: data.banner.link });
    }
  }, [id, data?.banner]);

  const submitHandler = async (e) => {
    e.preventDefault();
    const data = {
      id: bannerId,
      url: file,
      link: info.link,
      client_name: info.client_name,
    };

    if (UpdateCompError) {
      toast.warning(UpdateCompError, toastOptions);
      return;
    }

    updateBanner(data);
  };

  return (
    <EditForm
      {...props}
      title="Edit Banner"
      data={info}
      setData={setInfo}
      inputFieldProps={bannerAttr}
      submitHandler={submitHandler}
      target="/admin/banners"
      successMessage="Banner Updated Successfully! Redirecting..."
      reducerProps={{
        loadingUpdate: isUpdateBannerLoading,
        error: updateBannerError,
        success: isUpdateBannerSucess,
      }}
    >
      <div>
        {/* <label>Image</label> */}
        <UploadFileComp
          label="Banner Image"
          fileType={"image"}
          accept="image/*"
          file={file}
          getError={true}
          isDimension={true}
          setError={setUpdateCompError}
          setFile={setFile}
        />
      </div>
    </EditForm>
  );
}
