import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EditForm } from "../../components";
import {
  useGetProviderByIdQuery,
  useUpdateProviderMutation,
} from "../../store";
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import { toastOptions } from "../../utils/error";
import { toast } from "react-toastify";

export default function EditProvider(props) {
  const { id } = useParams();

  const { data: provider } = useGetProviderByIdQuery(id);
  const [value, setValue] = useState(null);

  const [info, setInfo] = useState({
    fullname: null,
    id: null,
    email: null,
    mobile_no: null,
    country_code: null,
    business_name: null,
    business_location: null,
    facebook: null,
    instagram: null,
    website: null,
    onHold: null,
  });

  const userAttr = [
    {
      type: "check",
      col: 12,
      props: {
        label: "On Hold",
        name: "onHold",
      },
    },
    {
      type: "text",
      col: 12,
      props: {
        label: "Fullname",
        name: "fullname",
        maxLength: 50,
        minLength: 4,
        required: true,
        placeholder: "Enter your fullname",
      },
    },
    {
      type: "email",
      col: 12,
      props: {
        label: "Email",
        name: "email",
        required: true,
        placeholder: "Enter your email address",
      },
    }, {
      type: "text",
      col: 12,
      props: {
        label: "Business Location",
        name: "business_location",
        required: true,
        placeholder: "Enter your Business location",
      },
    }, {
      type: "text",
      col: 12,
      props: {
        label: "Business Name",
        name: "business_name",
        required: true,
        placeholder: "Enter your Business name",
      },
    }, {
      type: "text",
      col: 12,
      props: {
        label: "Facebook",
        name: "facebook",
        required: true,
        placeholder: "Enter your facebook link",
      },
    }, {
      type: "text",
      col: 12,
      props: {
        label: "Instagram",
        name: "instagram",
        required: true,
        placeholder: "Enter your instagram link",
      },
    }, {
      type: "text",
      col: 12,
      props: {
        label: "Website",
        name: "website",
        required: true,
        placeholder: "Enter your website link",
      },
    },

  ];

  const [
    updateProvider,
    {
      isLoading: isUpdateProviderLoading,
      isSuccess: isUpdateProviderSucess,
      error: updateProviderError,
    },
  ] = useUpdateProviderMutation();

  useEffect(() => {
    if (provider && provider?.provider.id === id) {
      setInfo({
        fullname: provider.provider.fullname,
        id: provider.provider.id,
        email: provider.provider.email,
        mobile_no: provider.provider.mobile_no,
        country_code: provider.provider.country_code,
        onHold: provider.provider.onHold,
        business_location: provider.provider.buisness_location,
        business_name: provider.provider.buisness_name,
        facebook: provider.provider.facebook,
        instagram: provider.provider.instagram,
        website: provider.provider.website,
      });
      console.log(
        "value",
        provider.provider.country_code + provider.provider.mobile_no
      );
      setValue(provider.provider.country_code + provider.provider.mobile_no);
    }
  }, [id, provider]);

  const submitHandler = async (e) => {
    e.preventDefault();
    // if (!isValidPhoneNumber(info.country_code + info.mobile_no)) {
    //   toast.error("Please fill a valid mobile number", toastOptions);
    //   return;
    // }
    updateProvider(info);
  };
  console.log("providerinfo", info);

  return (
    <EditForm
      {...props}
      title="Update Provider"
      data={info}
      setData={setInfo}
      inputFieldProps={userAttr}
      submitHandler={submitHandler}
      target="/admin/providers"
      successMessage="Provider Info Updated Successfully! Redirecting..."
      reducerProps={{
        loadingUpdate: isUpdateProviderLoading,
        error: updateProviderError,
        success: isUpdateProviderSucess,
      }}
    >
      <div>
        <div>
          <p style={{ marginBottom: "0.5rem" }}>Mobile No.</p>
          <PhoneInput
            className="form-control mb-3"
            placeholder="Enter phone number"
            value={value}
            // onCountryChange={(e) => {
            //   setValue("+" + info.country_code + info.mobile_no);
            // }}
            onChange={(e) => {
              // console.log("phone number input", e);
              if (e) {
                const countryCode = parsePhoneNumber(e)?.countryCallingCode;
                const mobileNum = parsePhoneNumber(e)?.nationalNumber;

                // console.log("in the info", countryCode, mobileNum);

                setInfo({
                  ...info,
                  country_code: "+" + countryCode,
                  mobile_no: mobileNum,
                });
                setValue("+" + countryCode + mobileNum);
              }
            }}
          />
        </div>
      </div>
    </EditForm>
  );
}
