//get all users
export const getAllUsers = (query) => {
  return {
    url: "/",
    method: "GET",
    params: {
      keyword: query.keyword,
      currentPage: query.currentPage,
      resultPerPage: query.resultPerPage,
    },
  };
};

//get user by id
export const getUserById = (userId) => {
  return {
    url: `/${userId}`,
    method: "GET",
  };
};

//update user
export const updateUser = (user) => {
  return {
    url: `/${user.id}`,
    method: "PUT",
    body: { ...user },
  };
};

//delete user
export const deleteUser = (userId) => {
  return {
    url: `/${userId}`,
    method: "DELETE",
  };
};

//lock/unlock user
export const lockUnlockUser = (userId) => {
  return {
    url: `/state/${userId}`,
    method: "PUT",
  };
};
