import React, {
  useContext,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import JoditEditor from "jodit-react";

import { ToastContainer, toast } from "react-toastify";
import { useTitle, AddForm } from "../../components";
import { useCreateContentMutation } from "../../store";

const TCU = "TERMS_AND_CONDITIONS_USER";
const TCP = "TERMS_AND_CONDITIONS_PROVIDER";
const PPU = "PRIVACY_POLICY_USER";
const PPP = "PRIVACY_POLICY_PROVIDER";

export default function AddContent() {
  const [searchParams, _] = useSearchParams(document.location.search);
  const contentType = searchParams.get("TYPE");
  let title =
    contentType === "TCU" || contentType === "TCP"
      ? "Terms & Conditions"
      : "Privacy Policy";

  const editor = useRef();
  const [content, setContent] = useState();
  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: "Write your content here...",
    }),
    []
  );
  const [
    createContent,
    {
      isLoading: isCreateContentLoading,
      isError: isCreateContentError,
      error: createContentError,
      isSuccess: isCreateContentSuccess,
    },
  ] = useCreateContentMutation();

  const resetForm = () => setContent("");

  const submitHandler = async (e) => {
    e.preventDefault();

    let title;

    if (contentType === "TCU") {
      title = TCU;
    } else if (contentType === "TCP") {
      title = TCP;
    } else if (contentType === "PPU") {
      title = PPU;
    } else {
      title = PPP;
    }

    console.log("......add content", { title, desc: content });
    createContent({ title, desc: content });
    resetForm();
  };

  useTitle(`Content | ${title}`);
  return (
    <AddForm
      title={`Add ${title}`}
      data={{}}
      setData={() => {}}
      inputFieldProps={[]}
      submitHandler={submitHandler}
      target="/admin/content"
      successMessage={`${title} Created Successfully!`}
      reducerProps={{
        isLoading: isCreateContentLoading,
        error: createContentError,
        isSuccess: isCreateContentSuccess,
      }}
    >
      <JoditEditor
        ref={editor}
        value={content}
        config={config}
        tabIndex={1} // tabIndex of textarea
        // onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
        onChange={(newContent) => setContent(newContent)}
      />
      {/* {content} */}
      <ToastContainer />
    </AddForm>
  );
}
