import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EditForm, UploadFileComp } from "../../components";
import { useGetUserByIdQuery, useUpdateUserMutation } from "../../store";

import { toastOptions } from "../../utils/error";
import { toast } from "react-toastify";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";

export default function EditUser(props) {
  const { id } = useParams();

  const { data: user } = useGetUserByIdQuery(id);
  const [value, setValue] = useState(null);

  const [info, setInfo] = useState({
    fullname: "",
    profileImage: null,
    email: "",
    id: null,
    mobile_no: null,
    country_code: null,
  });

  const userAttr = [
    {
      type: "text",
      col: 12,
      props: {
        label: "Fullname",
        name: "fullname",
        maxLength: 50,
        minLength: 4,
        required: true,
        placeholder: "Enter your fullname",
      },
    },
    // {
    //   type: "email",
    //   col: 12,
    //   props: {
    //     label: "Email",
    //     name: "email",
    //     required: true,
    //     placeholder: "Enter your email address",
    //   },
    // },
  ];

  const handleImageChange = (location) => {
    setInfo({ ...info, profile_url: location });
  };

  const [
    updateUser,
    {
      isLoading: isUpdateUserLoading,
      isSuccess: isUpdateUserSucess,
      error: updateUserError,
    },
  ] = useUpdateUserMutation();

  useEffect(() => {
    if (user) {
      setInfo({
        fullname: user.user.fullname,
        id: user.user.id,
        email: user.user.email,
        profileImage: user.user.profileImage,
        mobile_no: user.user.mobile_no,
        country_code: user.user.country_code,
      });
      console.log("value", user.user.country_code + user.user.mobile_no);
      setValue(user.user.country_code + user.user.mobile_no);
    }
  }, [id, user]);

  const submitHandler = async (e) => {
    e.preventDefault();
    // if (!isValidPhoneNumber(info.country_code + info.mobile_no)) {
    //   toast.error("Please fill a valid mobile number", toastOptions);
    //   return;
    // }
    console.log("userinfo", info);
    updateUser(info);
  };

  return (
    <EditForm
      {...props}
      title="Update User"
      data={info}
      setData={setInfo}
      inputFieldProps={userAttr}
      submitHandler={submitHandler}
      target="/admin/users"
      successMessage="User Info Updated Successfully! Redirecting..."
      reducerProps={{
        loadingUpdate: isUpdateUserLoading,
        error: updateUserError,
        success: isUpdateUserSucess,
      }}
    >
      <div>
        <div>
          <p style={{ marginBottom: "0.5rem" }}>Mobile No.</p>
          <PhoneInput
            className="form-control mb-3"
            placeholder="Enter phone number"
            value={value}
            onChange={(e) => {
              console.log("phone number input", e);
              if (e) {
                const countryCode = parsePhoneNumber(e)?.countryCallingCode;
                const mobileNum = parsePhoneNumber(e)?.nationalNumber;

                // console.log("in the info", countryCode, mobileNum);

                setInfo({
                  ...info,
                  country_code: "+" + countryCode,
                  mobile_no: mobileNum,
                });
                setValue("+" + countryCode + mobileNum);
              }
            }}
          />
        </div>
        <UploadFileComp
          label="Profile image"
          fileType={"image"}
          accept="image/*"
          file={info.profileImage}
          setFile={handleImageChange}
        />
      </div>
    </EditForm>
  );
}
