import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

import { useTitle, ViewCard } from "../components";
import EditProfileModel from "./UpdateProfile";
import { useGetProfileQuery } from "../store";

const keyProps = {
  Email: "email",
  Fullname: "fullname",
  "Mobile No.": "mobile_no",
  // Gender: "gender",
  Role: "role",
  "Created At": "createdAt",
  "Last Update": "updatedAt",
};

const ViewProfile = () => {
  const [modalShow, setModalShow] = useState(false);
  const { data, isLoading, isSuccess, error, refetch } = useGetProfileQuery();

  useTitle("Profile Details");

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <ViewCard
      isImage={true}
      image_url={data?.user ? data.user?.profileImage : ""}
      title={"Profile Details"}
      data={
        data?.user && {
          ...data?.user,
          mobile_no: `${data?.user?.country_code} ${data?.user?.mobile_no}`,
        }
      }
      setModalShow={setModalShow}
      keyProps={keyProps}
      reducerProps={{ error, isLoading, isSuccess }}
    >
      <EditProfileModel show={modalShow} onHide={() => setModalShow(false)} />
      {!modalShow && <ToastContainer />}
    </ViewCard>
  );
};

export default ViewProfile;
