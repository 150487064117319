import { useState } from "react";
import {
  AddForm,
  UploadFileComp,
  useTitle,
} from "../../components";
import {
  useCreateServiceMutation,
  useGetAllCategoriesQuery,
} from "../../store";
import { ToastContainer, toast } from "react-toastify";
import { toastOptions } from "../../utils/error";

export default function AddService() {
  useTitle("Create Service");

  const [file, setFile] = useState(null);
  const [info, setInfo] = useState({ title: "", categoryId: "" });

  const [addService, { isLoading, isSuccess, error }] =
    useCreateServiceMutation();

  const {
    data: categories,
    // isLoading: isCategoryLoading,
    // isError: isCategoryError,
    // error: categoryError,
  } = useGetAllCategoriesQuery({
    keyword: "",
  });

  //submit
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      ...info,
      image: file,
    };

    if (!data.categoryId) {
      toast.error("Please select a category", toastOptions);
      return;
    }

    addService(data);
  };

  const selectOptions = categories?.categories.map((category) => {
    return { [category.id]: category.categoryName };
  });

  const serviceAttr = [
    {
      type: "text",
      col: 12,
      props: {
        label: "Title",
        name: "title",
        maxLength: 50,
        minLength: 4,
        required: true,
        placeholder: "Service title",
      },
    },
    {
      type: "select",
      col: 12,
      props: {
        label: "Category",
        name: "categoryId",
        required: true,
        options: selectOptions,
        placeholder: "Select category",
      },
    },
  ];

  return (
    <AddForm
      title="Add Service"
      data={info}
      setData={setInfo}
      inputFieldProps={serviceAttr}
      submitHandler={handleSubmit}
      target="/admin/services"
      successMessage="Service Added Successfully!"
      reducerProps={{ isLoading, error, isSuccess }}
    >
      <div>
        <UploadFileComp
          required={true}
          label="Image"
          fileType={"image"}
          accept="image/*"
          file={file}
          setFile={setFile}
        />
      </div>
      <ToastContainer />
    </AddForm>
  );
}
