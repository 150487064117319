import { ToastContainer, toast } from "react-toastify";
import {
  CustomTable,
  DeleteButton,
  MessageBox,
  MotionDiv,
  ViewButton,
  useTitle,
} from "../../components";
import { useEffect, useState } from "react";
import {
  useDeleteCategoryMutation,
  useGetAllCategoriesQuery,
} from "../../store";
import { useNavigate } from "react-router-dom";
import { toastOptions } from "../../utils/error";

export default function Category() {
  useTitle("Category");
  const [, setQuery] = useState("che");
  const [currentPage, setCurrentPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();

  const {
    data: categories,
    isLoading: isCategoryLoading,
    isError: isCategoryError,
    error: categoryError,
    refetch
  } = useGetAllCategoriesQuery({
    keyword: searchInput,
    currentPage,
    resultPerPage,
  });

  const [
    removeCategory,
    {
      isLoading: isCategoryDeleteLoading,
      isError: isCategoryDeleteError,
      error: categoryDeleteError,
      isSuccess: isCategoryDeletedSuccess,
    },
  ] = useDeleteCategoryMutation();

  const curPageHandler = (p) => setCurrentPage(p);

  const column = ["S.No", "Category Name", "Actions"];
  const numOfPages = Math.ceil(categories?.count / resultPerPage);
  const skip = resultPerPage * (currentPage - 1);

  useEffect(() => {
    if (isCategoryDeleteError) {
      toast.error(categoryDeleteError?.data?.error.message, toastOptions);
    }

    if (isCategoryDeletedSuccess) {
      toast.success("Category Deleted Successfully.", toastOptions);
    }
  }, [isCategoryDeletedSuccess, isCategoryDeleteError, categoryDeleteError]);

  useEffect(() => {
    refetch({ keyword: searchInput, currentPage, resultPerPage });
  }, [currentPage, refetch, resultPerPage, searchInput]);

  return (
    <MotionDiv>
      {isCategoryError ? (
        <MessageBox variant="danger">
          {categoryError.data.error.message}
        </MessageBox>
      ) : (
        <CustomTable
          loading={isCategoryLoading}
          column={column}
          rowNo={resultPerPage}
          rowProps={{ setResultPerPage }}
          paging={numOfPages > 0}
          pageProps={{ numOfPages, currentPage }}
          pageHandler={curPageHandler}
          search={true}
          searchProps={{ searchInput, setSearchInput, setQuery }}
          isCreateBtn="true"
          createBtnProps={{
            createURL: "/admin/category/create",
            text: "Category",
          }}
          // isTitle="true"
          // title="Category"
        >
          {categories?.categories &&
            categories.categories.map((category, i) => {
              return (
                <tr key={i} className="odd">
                  <td className="text-center">{skip + i + 1}</td>
                  <td>{category.categoryName}</td>
                  <td>
                    <ViewButton
                      onClick={() =>
                        navigate(`/admin/view/category/${category.id}`)
                      }
                    />
                    <DeleteButton onClick={() => removeCategory(category.id)} />
                  </td>
                </tr>
              );
            })}
        </CustomTable>
      )}
      <ToastContainer />
    </MotionDiv>
  );
}
