// get all services
export const getAllServices = (query) => ({
  url: "/",
  method: "GET",
  params: {
    keyword: query.keyword,
    currentPage: query.currentPage,
    resultPerPage: query.resultPerPage,
  },
});

//get service by service id
export const getService = (serviceId) => ({
  url: `/${serviceId}`,
  method: "GET",
});

//create new Service
export const createService = (service) => ({
  url: "/",
  method: "POST",
  body: {
    title: service.title,
    image: service.image,
    categoryId: service.categoryId,
  },
});

//update existing service
export const updateService = (service) => ({
  url: `/${service.id}`,
  method: "PUT",
  body: {
    title: service.title,
    image: service.image,
    categoryId: service.categoryId,
  },
});

//delete service by id
export const deleteService = (serviceId) => ({
  url: `/${serviceId}`,
  method: "DELETE",
});
