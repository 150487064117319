import { Form, Button, Modal, Container } from "react-bootstrap";
import SubmitButton from "./SubmitButton";

export default function CustomDialog({
  show,
  onHide,
  title,
  onSubmit,
  content,
  loading,
  cancelText = "Cancel",
  confirmText = "Confirm",
}) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmit}>
        <Modal.Body>
          <Container className="small-container">{content}</Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onHide}>
            {cancelText}
          </Button>
          <SubmitButton variant="success" loading={loading} disabled={loading}>
            {confirmText}
          </SubmitButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
