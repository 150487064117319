//get all banners
export const getAllBanners = () => {
  return {
    url: "/banner",
    method: "GET",
  };
};

//get banner by id
export const getBannerById = (bannerId) => {
  return {
    url: `/banner/${bannerId}`,
    method: "GET",
  };
};

//create banner
export const createBanner = (banner) => {
  return {
    url: `/admin/banner`,
    method: "POST",
    body: { ...banner },
  };
};

//update banner
export const updateBanner = (banner) => {
  return {
    url: `/admin/banner/${banner.id}`,
    method: "PUT",
    body: {
      url: banner.url,
      link: banner.link,
      client_name: banner.client_name
    },
  };
};

//delete banner
export const removeBanner = (bannerId) => {
  return {
    url: `/admin/banner/${bannerId}`,
    method: "DELETE",
  };
};
