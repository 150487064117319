import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { FaEnvelope, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import { useTitle } from "../components";
import { toastOptions } from "../utils/error";
import { setAuth, useLoginMutation } from "../store";
import { useDispatch } from "react-redux";

export default function AdminLoginScreen() {
  // const [role, setRole] = useState("admin");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPwd, setShowPwd] = useState(false);
  const [
    loginUser,
    {
      isLoading: isLoginLoading,
      error: loginError,
      isError: isLoginError,
      isSuccess: isLoginSuccess,
      data: userData,
    },
  ] = useLoginMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const submitHandler = async (e) => {
    e.preventDefault();
    if (!email || !password) return;

    loginUser({ email, password });
  };

  useEffect(() => {
    if (isLoginError) {
      toast.error(loginError?.data.error.message, toastOptions);
    }
  }, [loginError?.data, isLoginError]);

  useEffect(() => {
    if (isLoginSuccess) {
      navigate("/admin/dashboard", { replace: true });
    }
  }, [isLoginSuccess, navigate]);

  useEffect(() => {
    dispatch(setAuth(userData));
  }, [dispatch, userData]);

  useTitle("Login");
  return (
    <Container fluid className="p-0 vh-100 f-center flex-column login-page">
      <div className="login-logo">
        <Link to="/" className="text-center">
          <b>MY PITCH</b>
        </Link>
      </div>

      <Card className="login-box">
        <Card.Body>
          <p className="text-center">Sign in to start your session</p>
          <Form onSubmit={submitHandler}>
            {/* Email */}
            <Form.Group controlId="text" className="input-group mb-3">
              <Form.Control
                placeholder="Email/Mobile"
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <InputGroup.Text>
                <FaEnvelope />
              </InputGroup.Text>
            </Form.Group>
            {/* password */}
            <Form.Group controlId="password" className="input-group mb-3">
              <Form.Control
                placeholder="Password"
                type={showPwd ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <InputGroup.Text
                className="toggle-pwd"
                onClick={() => setShowPwd(!showPwd)}
              >
                {showPwd ? <FaEyeSlash /> : <FaEye />}
              </InputGroup.Text>
              <InputGroup.Text>
                <FaLock />
              </InputGroup.Text>
            </Form.Group>
            <Row>
              <Col sm={7} className="mb-sm-0 mb-3">
                <Form.Group controlId="remember">
                  <Form.Check
                    type="checkbox"
                    id="default-checkbox"
                    label="Remember Me"
                  />
                </Form.Group>
              </Col>
              <Col sm={5}>
                {isLoginLoading ? (
                  <Button type="submit" className="float-sm-end" disabled>
                    <Spinner animation="border" size="sm" />
                  </Button>
                ) : (
                  <Button
                    id="login_submit"
                    type="submit"
                    className="float-sm-end"
                  >
                    Sign In
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
          <ToastContainer />
        </Card.Body>
      </Card>
    </Container>
  );
}
