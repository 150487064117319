import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { termsAndConditions, privacyPolicy, deleteUserUrl, submitQueryUrl } from "../routes";
import { headerWithAuthToken } from "../headers";
import { getContent, deleteUser, submitQuery } from "./queries";

const tandcApi = createApi({
    reducerPath: "tandcApi",
    baseQuery: fetchBaseQuery({
        baseUrl: termsAndConditions,
        prepareHeaders: headerWithAuthToken,
    }),
    endpoints: (builder) => ({
        getContent: builder.query({
            query: getContent,
        }),
    }),
});

const privacyApi = createApi({
    reducerPath: "privacyApi",
    baseQuery: fetchBaseQuery({
        baseUrl: privacyPolicy,
        prepareHeaders: headerWithAuthToken,
    }),
    endpoints: (builder) => ({
        getContent: builder.query({
            query: getContent,
        }),
    }),
});

const deleteUserApi = createApi({
    reducerPath: "deleteUserApi",
    baseQuery: fetchBaseQuery({
        baseUrl: deleteUserUrl,
        prepareHeaders: headerWithAuthToken,
    }),
    endpoints: (builder) => ({
        deleteUser: builder.mutation({
            query: deleteUser
        }),
    }),
});

const submitQueryApi = createApi({
    reducerPath: "submitQueryApi",
    baseQuery: fetchBaseQuery({
        baseUrl: submitQueryUrl,
        prepareHeaders: headerWithAuthToken,
    }),
    endpoints: (builder) => ({
        submitQuery: builder.mutation({
            query: submitQuery
        }),
    }),
});

export const {
    useGetContentQuery: useGetTandCQuery,
} = tandcApi;

export const {
    useGetContentQuery: useGetPrivacyQuery,
} = privacyApi;

export const {
    useDeleteUserMutation: useDeleteUser,
} = deleteUserApi;

export const {
    useSubmitQueryMutation: useSubmitQuery,
} = submitQueryApi;

export { tandcApi, privacyApi, deleteUserApi, submitQueryApi };
