import { ToastContainer, toast } from "react-toastify";
import {
  CustomTable,
  DeleteButton,
  MessageBox,
  MotionDiv,
  ViewButton,
  useTitle,
} from "../../components";
import { useEffect, useState } from "react";
import { useGetRecentEnquiriesQuery } from "../../store";
import { getDateTime } from "../../utils/function";

export default function Enquiries() {
  useTitle("Enquiries");
  const [currentPage, setCurrentPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);

  //   const navigate = useNavigate();
  const sd = new Date();
  const ed = new Date();
  sd.setDate(sd.getDate() - 1);
  const formattedStartDate = sd.toISOString().split("T")[0];
  const formattedEndDate = ed.toISOString().split("T")[0];
  const [startDate, setStartDate] = useState(formattedStartDate);
  const [endDate, setEndDate] = useState(formattedEndDate);

  const {
    data: enquiries,
    isLoading: isEnquiriesLoading,
    isError: isEnquiriesError,
    error: enquiriesError,
    refetch,
  } = useGetRecentEnquiriesQuery({
    currentPage,
    resultPerPage,
    startDate,
    endDate,
  });

  console.log("............enquiries", enquiries?.enquiries);

  const numOfPages = Math.ceil(enquiries?.count / resultPerPage);
  //   const skip = resultPerPage * (currentPage - 1);
  const curPageHandler = (p) => setCurrentPage(p);

  const column = [
    "S.No",
    "Service Title",
    "Image",
    "Provider Name",
    "Provider Mobile No",
    "User Name",
    "User Email",
    "User Mobile No",
    "Enquiry Created At",
  ];

  useEffect(() => {
    refetch({
      currentPage,
      resultPerPage,
      startDate,
      endDate,
    });
  }, [refetch, currentPage, resultPerPage, startDate, endDate]);

  const isDate = (date) => {
    return (
      new Date(date) instanceof Date &&
      new Date(date) !== "Invalid Date" &&
      !isNaN(new Date(date))
    );
  };

  const getDate = (val) => {
    const res = val ? (isDate(val) ? getDateTime(val) : val) : "---";
    return res;
  };

  const handleOnDateChange = (e) => {
    if (e.target.name === "start") {
      setStartDate(e.target.value);
    } else {
      setEndDate(e.target.value);
    }
  };

  return (
    <MotionDiv>
      {isEnquiriesError ? (
        <MessageBox variant="danger">
          {enquiriesError.data.error.message}
        </MessageBox>
      ) : (
        <CustomTable
          loading={isEnquiriesLoading}
          column={column}
          rowNo={resultPerPage}
          rowProps={{ setResultPerPage }}
          paging={numOfPages > 0}
          pageProps={{ numOfPages, currentPage }}
          pageHandler={curPageHandler}
          otherComp={
            <div className="d-flex gap-2 align-items-center">
              <span className="fw-medium">BETWEEN</span>
              <div>
                <input
                  type="date"
                  name="start"
                  className="form-control"
                  onChange={handleOnDateChange}
                  value={startDate}
                />
              </div>
              <span className="fw-medium"> - </span>
              <div>
                <input
                  type="date"
                  name="end"
                  className="form-control"
                  onChange={handleOnDateChange}
                  value={endDate}
                />
              </div>
              {/* <button className="btn btn-primary" onClick={onHandleSubmit}>Find</button> */}
            </div>
          }
          isTitle="true"
          title="Enquiries"
        >
          {enquiries?.enquiries &&
            enquiries.enquiries.map((enquiry, i) => {
              return (
                <tr key={i} className="odd">
                  <td className="text-center">{i + 1}</td>
                  <td>{enquiry.Service ? enquiry.Service?.title : "---"}</td>
                  <td>
                    <img
                      className="td-img"
                      src={enquiry.Service?.image}
                      alt="img"
                    />
                  </td>
                  <td>
                    {enquiry.provider ? enquiry.provider?.fullname : "---"}
                  </td>
                  <td>
                    {enquiry.provider
                      ? `${enquiry.provider?.country_code} ${enquiry.provider?.mobile_no}`
                      : "---"}
                  </td>
                  <td>{enquiry.user?.fullname}</td>
                  <td>{enquiry.user?.email}</td>
                  <td>{`${enquiry.user?.country_code} ${enquiry.user?.mobile_no}`}</td>
                  <td>{getDate(enquiry.createdAt)}</td>
                </tr>
              );
            })}
        </CustomTable>
      )}
      <ToastContainer />
    </MotionDiv>
  );
}
