import { useState } from "react";
import { AddForm, UploadFileComp, useTitle } from "../../components";
import { useCreateBannerMutation } from "../../store";
import { ToastContainer, toast } from "react-toastify";
import { toastOptions } from "../../utils/error";

export default function AddBanner() {
  useTitle("Create Banner");

  const [file, setFile] = useState(null);
  const [info, setInfo] = useState({ link: null, client_name: null });
  const [UpdateCompError, setUpdateCompError] = useState(null);

  const [addBanner, { isLoading, isSuccess, error }] =
    useCreateBannerMutation();
  console.log(UpdateCompError);
  //submit
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!file) {
      toast.error("Please select file", toastOptions);
      return;
    }

    if (UpdateCompError) {
      toast.warning(UpdateCompError, toastOptions);
      return;
    }

    addBanner({ url: file, link: info.link, client_name: info.client_name });
  };

  const bannerAttr = [
    {
      type: "text",
      col: 12,
      props: {
        label: "Client Name",
        name: "client_name",
        placeholder: "Enter client name",
      },
    },
    {
      type: "text",
      col: 12,
      props: {
        label: "Navigation Link",
        name: "link",
        placeholder: "Enter banner navigation link",
      },
    },
  ];

  return (
    <AddForm
      title="Add Banner"
      data={info}
      setData={setInfo}
      inputFieldProps={bannerAttr}
      submitHandler={handleSubmit}
      target="/admin/banners"
      successMessage="Banner Added Successfully!"
      reducerProps={{ isLoading, error, isSuccess }}
    >
      <div>
        <UploadFileComp
          label="Banner Image"
          fileType={"image"}
          accept="image/*"
          file={file}
          isDimension={true}
          getError={true}
          setError={setUpdateCompError}
          setFile={setFile}
        />
      </div>
      <ToastContainer />
    </AddForm>
  );
}
