import { Navigate } from "react-router-dom";

export default function UnprotectedRoute({ children }) {
  // const { state } = useContext(Store);
  // const { userInfo } = state;
  const user = JSON.parse(localStorage.getItem('user'));
  return user && user.role === "Admin" ? (
    <Navigate to="/admin/dashboard" />
  ) : (
    children
  );
}
