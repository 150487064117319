import React, { useContext, useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import {
  MessageBox,
  useTitle,
  MotionDiv,
  DeleteButton,
  EditButton,
} from "../../components";
import { toastOptions } from "../../utils/error";
import { Button, Card } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useDeleteContentMutation, useGetContentQuery } from "../../store";

const TCU = "TERMS_AND_CONDITIONS_USER";
const TCP = "TERMS_AND_CONDITIONS_PROVIDER";
const PPU = "PRIVACY_POLICY_USER";
const PPP = "PRIVACY_POLICY_PROVIDER";

const ContentCard = ({ content, url, text, onEdit, onDelete }) => {
  const navigate = useNavigate();

  return (
    <Card>
      <Card.Header>
        {content ? (
          <>
            <h3 className="mb-0 d-inline">{text}</h3>
            <div className="float-end">
              <EditButton onClick={onEdit} />
              <DeleteButton onClick={onDelete} />
            </div>
          </>
        ) : (
          <Button
            onClick={() => navigate(url)}
            type="success"
            className="btn btn-primary btn-block mt-1"
          >
            Add {text}
          </Button>
        )}
      </Card.Header>
      <Card.Body>
        {content && (
          <div
            style={{ maxHeight: "50vh", overflowY: "auto" }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default function Content() {
  const navigate = useNavigate();

  const {
    data: content,
    isLoading: isContentLoading,
    isError: isContentError,
    error: contentError,
    isSuccess: isContentSuccess,
    refetch,
  } = useGetContentQuery();

  const [
    removeContent,
    {
      isLoading: isContentDeleteLoading,
      isError: isContentDeleteError,
      error: contentDeleteError,
      isSuccess: isContentDeleteSuccess,
    },
  ] = useDeleteContentMutation();

  useEffect(() => {
    if (isContentDeleteError) {
      toast.error(contentDeleteError.data.error.message, toastOptions);
    }

    if (isContentDeleteSuccess) {
      toast.success("Content Deleted Successfully.", toastOptions);
    }
  }, [
    contentDeleteError?.data.error.message,
    isContentDeleteError,
    isContentDeleteSuccess,
  ]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (isContentDeleteSuccess) {
      refetch();
    }
  }, [isContentDeleteSuccess, refetch]);
  console.log(".......content", content);
  useTitle("Content");
  return (
    <MotionDiv>
      {isContentError ? (
        <MessageBox variant="danger">
          {contentError.data.error.message}
        </MessageBox>
      ) : isContentLoading ? (
        <Skeleton count={5} height={40} />
      ) : (
        <>
          <h4>End Users</h4>
          <ContentCard
            content={content?.ttU?.desc}
            url="/admin/content/create/?TYPE=TCU"
            text="Terms & Conditions"
            onEdit={() => navigate("/admin/content/update/?TYPE=TCU")}
            onDelete={() => removeContent(TCU)}
          />
          <br />
          <ContentCard
            content={content?.ppU?.desc}
            url="/admin/content/create/?TYPE=PPU"
            text="Privacy Policy"
            onEdit={() => navigate("/admin/content/update/?TYPE=PPU")}
            onDelete={() => removeContent(PPU)}
          />
          <br />
          {/* <h4>Service Providers</h4>
          <ContentCard
            content={content?.ttP?.desc}
            url="/admin/content/create/?TYPE=TCP"
            text="Terms & Conditions"
            onEdit={() => navigate("/admin/content/update/?TYPE=TCP")}
            onDelete={() => removeContent(TCP)}
          />
          <br />
          <ContentCard
            content={content?.ppP?.desc}
            url="/admin/content/create/?TYPE=PPP"
            text="Privacy Policy"
            onEdit={() => navigate("/admin/content/update/?TYPE=PPP")}
            onDelete={() => removeContent(PPP)}
          /> */}
        </>
      )}
      <ToastContainer />
    </MotionDiv>
  );
}
