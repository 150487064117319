import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { providerBaseUrl } from "../routes";

import { headerWithAuthToken } from "../headers";
import {
  deleteProvider,
  getAllProviders,
  getProviderById,
  lockUnlockProvider,
  updateProvider,
} from "./queries";

const providerApi = createApi({
  reducerPath: "provider",
  // refetchOnFocus: true,
  // refetchOnMountOrArgChange:true,
  baseQuery: fetchBaseQuery({
    baseUrl: providerBaseUrl,
    prepareHeaders: headerWithAuthToken,
  }),
  endpoints: (builder) => ({
    //get all provider
    getAllProviders: builder.query({
      query: getAllProviders,
    }),

    //get provider by id
    getProviderById: builder.query({
      query: getProviderById,
    }),

    //update provider
    updateProvider: builder.mutation({
      query: updateProvider,
    }),

    //delete provider
    deleteProvider: builder.mutation({
      query: deleteProvider,
    }),

    //lock/unlock provider
    lockUnlockProvider: builder.mutation({
      query: lockUnlockProvider,
    }),
  }),
});

export const {
  useGetAllProvidersQuery,
  useGetProviderByIdQuery,
  useLockUnlockProviderMutation,
  useUpdateProviderMutation,
  useDeleteProviderMutation,
  reducer: providerReducer,
  reducerPath: providerReducerPath,
  middleware: providerMiddleware,
} = providerApi;
