import { useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { AdminProtectedRoute, UnprotectedRoute } from "./routes";
import { Header, SideNavBar, NotFound } from "./components";
import {
  AdminLoginScreen,
  Dashboard,
  Services,
  Category,
  AddCategory,
  ViewCategory,
  AddService,
  ViewService,
  User,
  UserView,
  Providers,
  ProviderView,
  Content,
  AddContent,
  EditContent,
  Banners,
  AddBanner,
  ViewBanner,
  Profile,
  Enquiries,
  ContactUs,
  PrivacyPolicy,
  DeleteAccount,
  TermsAndConditions
} from "./pages";



function App() {
  const token = JSON.parse(localStorage.getItem("token"));

  const pageLocation = useLocation();

  const [isExpanded, setExpandState] = useState(window.innerWidth > 768);
  const sidebarHandler = () => setExpandState((prev) => !prev);

  const routeList = [
    { path: "/admin/view-profile", element: <Profile /> },
    { path: "/admin/dashboard", element: <Dashboard /> },

    //users
    { path: "/admin/users", element: <User /> },
    { path: "/admin/View/users/:id", element: <UserView /> },

    //services
    { path: "/admin/services", element: <Services /> },
    { path: "/admin/services/create", element: <AddService /> },
    { path: "/admin/view/services/:id", element: <ViewService /> },

    //categories
    { path: "/admin/category", element: <Category /> },
    { path: "/admin/category/create", element: <AddCategory /> },
    { path: "/admin/view/category/:id", element: <ViewCategory /> },

    //providers
    { path: "/admin/providers", element: <Providers /> },
    { path: "/admin/view/providers/:id", element: <ProviderView /> },

    //content
    { path: "/admin/content", element: <Content /> },
    { path: "/admin/content/create", element: <AddContent /> },
    { path: "/admin/content/update", element: <EditContent /> },

    //banners
    { path: "/admin/banners", element: <Banners /> },
    { path: "/admin/banners/create", element: <AddBanner /> },
    { path: "/admin/view/banners/:id", element: <ViewBanner /> },

    //enquiries
    { path: "/admin/enquiries", element: <Enquiries /> },
  ];

  return (
    <div className="main-wrapper">
      {isExpanded && token && (
        <div className="sidebar-overlay" onClick={sidebarHandler}></div>
      )}
      <div className="sidebar-wrapper">
        <SideNavBar isExpanded={isExpanded} />
      </div>
      <div
        className={`body-wrapper ${isExpanded ? "mini-body" : "full-body"}
        ${token ? "" : "m-0"} d-flex flex-column`}
      >
        <Header sidebarHandler={sidebarHandler} />
        <Routes location={pageLocation} key={pageLocation.pathname}>
          {/* Public Routes */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
          <Route path="/contact-us" element={<ContactUs />} />

          <Route
            path="/"
            element={
              <UnprotectedRoute>
                <AdminLoginScreen />
              </UnprotectedRoute>
            }
          />

          {/* Protected Admin Routes */}
          {routeList.map(({ path, element }) => (
            <Route
              key={path}
              path={path}
              element={<AdminProtectedRoute>{element}</AdminProtectedRoute>}
            />
          ))}

          <Route path="*" element={<NotFound />} />
        </Routes>
        {/* <Footer /> */}
      </div>
    </div>
  );
}

export default App;
