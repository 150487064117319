import { useState } from "react";
import { AddForm, useTitle } from "../../components";
import { useCreateCategoryMutation } from "../../store";
import { ToastContainer } from "react-toastify";

export default function AddCategory() {
  useTitle("Create Category");
  const [info, setInfo] = useState({ name: "" });
  const [addCategory, { isLoading, isSuccess, error, data }] =
    useCreateCategoryMutation();
  console.log("error: ", error);
  console.log("data added category: ", data);
  //submit
  const handleSubmit = (e) => {
    e.preventDefault();
    addCategory(info);
  };

  const categoryAttr = [
    {
      type: "text",
      col: 12,
      props: {
        label: "Category Name",
        name: "name",
        maxLength: 50,
        minLength: 4,
        required: true,
        placeholder:"Category name"
      },
    },
  ];

  return (
    <AddForm
      title="Add Category"
      data={info}
      setData={setInfo}
      inputFieldProps={categoryAttr}
      submitHandler={handleSubmit}
      target="/admin/category"
      successMessage="Category Added Successfully!"
      reducerProps={{ isLoading, error, isSuccess }}
    >
      <ToastContainer />
    </AddForm>
  );
}
