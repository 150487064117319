import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { serviceBaseUrl } from "../routes";

import { headerWithAuthToken } from "../headers";
import { uploadImage } from "./queries";

const sharedApi = createApi({
  reducerPath: "service",
  baseQuery: fetchBaseQuery({
    baseUrl: serviceBaseUrl,
    prepareHeaders: headerWithAuthToken,
  }),
  endpoints: (builder) => ({
    //get image url / store image to s3
    uploadImage: builder.mutation({
      query: uploadImage,
    }),
  }),
});

export const {
  useUploadImageMutation,
  reducer: sharedReducer,
  reducerPath: sharedReducerPath,
  middleware: sharedMiddleware,
} = sharedApi;
