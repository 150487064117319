import { createSlice } from "@reduxjs/toolkit";
// import { adminAuthEndpoints } from "./authApi";

const initialState = {
  token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null,
};

const adminSlice = createSlice({
  name: "admin-slice",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      if(!action.payload) return 
      state.user = action.payload.admin;
      state.token = action.payload.token;

      localStorage.setItem("token", JSON.stringify(action.payload.token));
      localStorage.setItem("user", JSON.stringify(action.payload.admin));
    },
    clearAuth: (state) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem("token");
      localStorage.removeItem("user");
    },
  },
  // extraReducers(builder) {
  //   builder.addMatcher(
  //     adminAuthEndpoints.login.matchFulfilled,
  //     (state, action) => {
  //       state.user = action.payload.user;
  //       state.token = JSON.parse(action.payload.token);
  //     }
  //   );
  // },
});

export const { setAuth, clearAuth } = adminSlice.actions;
export const adminSliceReducer = adminSlice.reducer;
export const selectAuth = (state) => state.user;
