import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { serviceBaseUrl } from "../routes";
import {
  createService,
  deleteService,
  getAllServices,
  getService,
  updateService,
} from "./queries";
import { headerWithAuthToken } from "../headers";

const serviceApi = createApi({
  reducerPath: "service",
  // refetchOnFocus: true,
  // refetchOnMountOrArgChange:true,
  baseQuery: fetchBaseQuery({
    baseUrl: serviceBaseUrl,
    prepareHeaders: headerWithAuthToken
  }),
  endpoints: (builder) => ({
    //get all services
    getAllServices: builder.query({
      query: getAllServices,
    }),

    //get service by id
    getService: builder.query({
      query: getService,
    }),

    //create new service
    createService: builder.mutation({
      query: createService,
    }),

    //update existing service
    updateService: builder.mutation({
      query: updateService,
    }),

    //delete existing service
    deleteService: builder.mutation({
      query: deleteService,
    }),
  }),
});

export const {
  useGetAllServicesQuery,
  useGetServiceQuery,
  useCreateServiceMutation,
  useDeleteServiceMutation,
  useUpdateServiceMutation,
  reducer: serviceReducer,
  reducerPath: serviceReducerPath,
  middleware: serviceMiddleware,
} = serviceApi;
