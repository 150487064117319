// get all categories
export const getAllCategories = (query) => ({
  url: "/",
  method: "GET",
  params: {
    keyword: query.keyword,
    currentPage: query.currentPage,
    resultPerPage: query.resultPerPage,
  },
});

// get category by id
export const getCategory = (categoryId) => ({
  url: `/${categoryId}`,
  method: "GET",
});

//create new category
export const createCategory = (category) => ({
  url: "/",
  method: "POST",
  body: {
    categoryName: category.name,
  },
});

//update existing category
export const updateCategory = (category) => ({
  url: `/${category.id}`,
  method: "PUT",
  body: {
    categoryName: category.name,
  },
});

//delete category
export const deleteCategory = (categoryId) => ({
  url: `/${categoryId}`,
  method: "DELETE",
});
