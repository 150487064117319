import { ToastContainer, toast } from "react-toastify";
import {
  CustomDialog,
  CustomTable,
  DeleteButton,
  MessageBox,
  MotionDiv,
  ViewButton,
  useTitle,
} from "../../components";
import { useEffect, useState } from "react";
import {
  useDeleteUserMutation,
  useGetAllUsersQuery,
  useLockUnlockUserMutation,
} from "../../store";
import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { toastOptions } from "../../utils/error";
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";

export default function User() {
  useTitle("User");
  const [, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [idToLock, setIdToLock] = useState({ id: null, isLocked: false });
  const [idToDelete, setIdToDelete] = useState(null);
  const navigate = useNavigate();

  const {
    data: users,
    isLoading: isUsersLoading,
    isError: isUsersError,
    error: usersError,
    refetch,
  } = useGetAllUsersQuery({ keyword: searchInput, currentPage, resultPerPage });

  const [
    removeUser,
    {
      isSuccess: isUserDeleted,
      isError: isUserDeleteError,
      error: userDeleteError,
      isLoading:isUserDeleteLoading
    },
  ] = useDeleteUserMutation();

  const [
    lockUnlockUser,
    {
      isSuccess: isLockUnlockUserSuccess,
      isError: isLockUnlockError,
      error: lockUnlockError,
      isLoading: isLockUnlockLoading
    },
  ] = useLockUnlockUserMutation();

  const handleLockUnlockState = (id, isLocked) => {
    setIdToLock({
      id,
      isLocked,
    });
  };

  const handleModalClose = () => setIdToLock({ id: null, isLocked: false });

  const handleDeleteModalOpen = (id) => setIdToDelete(id);
  const handleDeleteModalClose = () => setIdToDelete(null);

  const curPageHandler = (p) => setCurrentPage(p);

  const column = [
    "S.No",
    "Profile Image",
    "Fullname ",
    "Email",
    "Phone",
    "Verifed",
    "Locked",
    "Actions",
  ];
  const numOfPages = Math.ceil(users?.count / resultPerPage);
  const skip = resultPerPage * (currentPage - 1);

  useEffect(() => {
    if (isUserDeleteError) {
      toast.error(userDeleteError.data.error.message, toastOptions);
    }

    if (isUserDeleted) {
      toast.success("User Deleted Successfully.", toastOptions);
      handleDeleteModalClose()
    }
  }, [isUserDeleted, userDeleteError, isUserDeleteError]);

  useEffect(() => {
    if (isLockUnlockError) {
      toast.error(lockUnlockError.data.error.message, toastOptions);
    }

    if (isLockUnlockUserSuccess) {
      toast.success("User State Updated.", toastOptions);
      handleModalClose()
    }
  }, [isLockUnlockUserSuccess, lockUnlockError, isLockUnlockError]);

  useEffect(() => {
    refetch({ keyword: searchInput, currentPage, resultPerPage });
  }, [currentPage, refetch, resultPerPage, searchInput]);

  useEffect(() => {
    if (isLockUnlockUserSuccess) {
      refetch({ keyword: searchInput, currentPage, resultPerPage });
    }
  }, [
    currentPage,
    refetch,
    resultPerPage,
    searchInput,
    isLockUnlockUserSuccess,
  ]);

  useEffect(() => {
    if (isUserDeleted) {
      refetch({ keyword: searchInput, currentPage, resultPerPage });
    }
  }, [isUserDeleted, currentPage, refetch, resultPerPage, searchInput]);

  return (
    <MotionDiv>
      {isUsersError ? (
        <MessageBox variant="danger">
          {usersError.data.error.message}
        </MessageBox>
      ) : (
        <CustomTable
          loading={isUsersLoading}
          column={column}
          rowNo={resultPerPage}
          rowProps={{ setResultPerPage }}
          paging={numOfPages > 0}
          pageProps={{ numOfPages, currentPage }}
          pageHandler={curPageHandler}
          search={true}
          searchProps={{ searchInput, setSearchInput, setQuery }}
          isTitle="true"
          title="Users"
        >
          {users?.users &&
            users.users.map((user, i) => {
              console.log("user: ", user);
              return (
                <tr key={i} className="odd">
                  <td className="text-center">{skip + i + 1}</td>
                  <td>
                    <img className="td-img" src={user.profileImage} alt="img" />
                  </td>
                  <td>{user.fullname}</td>
                  <td>{user.email}</td>
                  <td>{user.mobile_no}</td>
                  <td className=" text-center">
                    {user.isVerified ? (
                      <FaCheck className="green" />
                    ) : (
                      <ImCross className="red" />
                    )}
                  </td>
                  <td className="">
                    {user.isLocked ? (
                      <button
                        className="rounded bg-warning px-2 text-white text-center"
                        name="unlock"
                        onClick={() =>
                          handleLockUnlockState(user.id, user.isLocked)
                        }
                      >
                        <FaLockOpen />
                      </button>
                    ) : (
                      <button
                        className="rounded bg-danger px-2 text-white text-center"
                        name="lock"
                        onClick={() =>
                          handleLockUnlockState(user.id, user.isLocked)
                        }
                      >
                        <FaLock />
                      </button>
                    )}
                  </td>
                  <td>
                    <ViewButton
                      onClick={() => navigate(`/admin/view/users/${user.id}`)}
                    />
                    <DeleteButton onClick={() => handleDeleteModalOpen(user.id)} />
                  </td>
                </tr>
              );
            })}
        </CustomTable>
      )}
      <CustomDialog
        show={idToLock.id}
        onHide={handleModalClose}
        title={"Are you sure?"}
        loading={isLockUnlockLoading}
        content={`You want to ${
          idToLock.isLocked ? "Unlock" : "Lock"
        } this user?`}
        onSubmit={(e) => {
          e.preventDefault();
          lockUnlockUser(idToLock.id);
        }}
      />
      <CustomDialog
        show={idToDelete}
        onHide={handleDeleteModalClose}
        title={"Are you sure?"}
        loading={isUserDeleteLoading}
        content={`You want to delete this user?`}
        onSubmit={(e) => {
          e.preventDefault();
          removeUser(idToDelete)
        }}
      />
      <ToastContainer />
    </MotionDiv>
  );
}
